import { Component, ComponentRef, OnInit, ViewContainerRef } from '@angular/core';
import { FormFieldDefinition } from '@models/form-field-definition';
import { FormService } from '@services/form.service';
import { FormDetailComponent } from '../base/form-detail/form-detail.component';
import { Form } from '@models/form';

@Component({
  selector: 'cp-account-display',
  templateUrl: './account-display.component.html',
  styleUrls: ['./account-display.component.less']
})
export class AccountDisplayComponent implements OnInit {
    constructor(private _formService: FormService, private _viewContainerRef: ViewContainerRef) {}

    ngOnInit(): void {
        this._formService.getForm("cpAccountInfo").subscribe(
            (fields: FormFieldDefinition[]) => {
                this.showForm(fields);
            }
        )
    }

    private showForm(fields: FormFieldDefinition[]) {
        const ref: ComponentRef<FormDetailComponent> =
            this._viewContainerRef.createComponent(FormDetailComponent);

        ref.instance.formId = 'cpAccountInfo';
        ref.instance.pageId = 'home';
        ref.instance.fields = Form.convertToFormFields(fields);
        ref.instance.dataQueryId = 'cpAccountInfo';
        ref.instance.autoRunDataQuery = true;
        ref.instance.isLoginForm = false;
        ref.instance.isAuthenticationForm = false;
        ref.changeDetectorRef.detectChanges();
    }
}
