import { Component, OnDestroy, OnInit } from '@angular/core';
import { CustomerAccount } from '@models/customer-account';
import { UserService } from '@services/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'cp-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.less']
})
export class AccountInfoComponent implements OnInit, OnDestroy {
    private _accountSubscription: Subscription;

    currentAccount: CustomerAccount;

    constructor(private _userService: UserService) {
        this._accountSubscription = this._userService.accountState$.subscribe({
            next: () => this.ngOnInit()
        });
    }

    ngOnInit(): void {
        this.currentAccount = this._userService.getCustomerAccount();
    }

    ngOnDestroy(): void {
        this._accountSubscription.unsubscribe();
    }
}
