import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SortService {
    private columnSortedSource: Subject<ColumnSortedEvent> = new Subject<ColumnSortedEvent>();

    columnSorted$: Observable<ColumnSortedEvent> = this.columnSortedSource.asObservable();

    columnSorted(event: ColumnSortedEvent) {
        this.columnSortedSource.next(event);
    }
}

export interface ColumnSortedEvent {
    tableId: number;
    sortColumn: number;
    sortDirection: string;
    queryId: string;
}
