import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { InternalDomain } from '@models/internal-domain';
import { Language } from '@models/language';
import { environment } from '@environment';
import { GeneralInfo } from '@models/general-info';
import { SetupInfo } from '@models/setup-info';

declare global {
    interface Window { cdAnalytics: any }
}

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
    static PRIVACY_ALERT: string = 'privacyAlert';
    private baseUrl: string = environment.ApiBase + 'home/';

    constructor(
        private _http: HttpClient,
    ) {}

    getLanguage(): string {
        const language = localStorage.getItem('currentLanguageId');
        return language === null || language === undefined ? 'en-us' : language;
    }

    getLanguageCode(): string {
        const language = localStorage.getItem('currentLanguageCode');
        return language === null || language === undefined ? 'en' : language;
    }

    getLanguages(): Observable<Language[]> {
        return this._http.get<Language[]>(this.baseUrl + 'languages');
    }

    getGeneralInfo(): Observable<GeneralInfo> {
        let params: HttpParams = new HttpParams();
        params = params.append('language', this.getLanguageCode());

        return this._http.get<GeneralInfo>(this.baseUrl + 'general-info', { params: params });
    }

    getDomainList(): Observable<InternalDomain[]> {
        return this._http.get<InternalDomain[]>(this.baseUrl + 'domains');
    }

    getSetupInfo(): Observable<SetupInfo[]> {
        let params: HttpParams = new HttpParams();
        params = params.append('language', this.getLanguageCode());

        return this._http.get<SetupInfo[]>(this.baseUrl + "setup-info", { params: params });
    }
}
