<div>
    <div>
        <h2 mat-dialog-title class="title" style="width: 100%;">{{action}} User</h2>
        <div style="clear: both"></div>
    </div>
</div>
<div style="padding: 24px;">
    <div>
        <mat-form-field class="field" style="width: 300px;">
            <mat-label>User Id</mat-label>
            <input matInput [(ngModel)]="currentUser.Id" [disabled]="action === 'Edit'">
        </mat-form-field>
        <mat-checkbox class="user-check" style="margin-left: 25px;" labelPosition="before" [(ngModel)]="currentUser.Enabled">Enabled</mat-checkbox>
    </div>
    <div>
        <input type="button" (click)="onSelectAccounts()" [disabled]="!canEditAccounts" value="Maintain Account Access" />
    </div>
    <div>
        <mat-form-field class="field" style="width: 300px">
            <mat-label>First Name</mat-label>
            <input matInput [(ngModel)]="currentUser.FirstName">
        </mat-form-field>
    </div>
    <div>
        <mat-form-field style="width: 300px">
            <mat-label>Last Name</mat-label>
            <input matInput [(ngModel)]="currentUser.LastName">
        </mat-form-field>
    </div>
    <div>
        <mat-form-field style="width: 300px">
            <mat-label>Company</mat-label>
            <input matInput [(ngModel)]="currentUser.CompanyName">
        </mat-form-field>
    </div>
    <div>
        <mat-form-field>
            <mat-label>User Role</mat-label>
            <mat-select [(ngModel)]="currentUser.Role" (valueChange)="setUserRole($event)">
                <mat-option *ngFor="let role of userRoleKeys" [value]="role">{{userRole[role]}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div mat-dialog-actions style="display: flex; justify-content: space-between;">
        <input type="button" (click)="onSave()" [disabled]="!canSave" value="Save" />
        <input type="button" (click)="onDelete()" [disabled]="!canDelete" value="Delete" />
        <input type="button" (click)="onCancel()" value="Cancel" />
    </div>
</div>
