import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { User } from '@models/user';
import { UserAccountData, UserAccountsComponent } from '../user-accounts/user-accounts.component';
import { UserCustomerAccounts } from '@models/user-customer-accounts';
import { UserService } from '@services/user.service';
import { UserRole } from '@models/user-role';

export class UserEditData {
    user: User;
    action: string;
    accounts: UserCustomerAccounts;
}

@Component({
  selector: 'cp-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.less']
})
export class UserEditComponent implements OnInit {
    static userIdValidator: RegExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    public userRole = UserRole;
    public userRoleKeys: string[];

    currentUser: User;
    originalUser: User;
    action: string;
    accounts: UserCustomerAccounts;

    canEditUser: boolean = false;

    get canEditAccounts(): boolean {
        return this.canEditUser && this.currentUser.Role != 'Admin' && this.currentUser.Role != 'SiteAdmin';
    }

    get canSave(): boolean {
        return this.isValidUserId(this.currentUser.Id) &&
               this.currentUser.CompanyName !== '' &&
               this.canEditUser;
    }

    get canDelete(): boolean {
        return this.canSave && (this.currentUser.Id !== this._userService.getCurrentUser().UserId);
    }

    setUserRole(role: UserRole): void {
        this.currentUser.Role = role;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: UserEditData,
        private _dialogRef: MatDialogRef<UserEditComponent>,
        private _accountsDialog: MatDialog,
        private _userService:UserService
    ) {
        if (!data.user) {
            data.user = new User();
        }
        this.currentUser = this.copyUser(data.user);
        this.originalUser = this.copyUser(data.user);
        this.action = data.action;
    }

    async ngOnInit(): Promise<void> {
        this.canEditUser = await this._userService.canEdit('users');

        if (await this._userService.canEdit('adminusers')) {
            this.userRoleKeys = Object.keys(UserRole).filter(f => isNaN(Number(f)));
        } else {
            this.userRoleKeys = ['Partner'];
        }

        this._userService.getCustomerAccountsForUser(this.currentUser.Id).subscribe({
            next: (accounts: UserCustomerAccounts) => {
                if (accounts) {
                    this.accounts = accounts;
                } else {
                    this.accounts = new UserCustomerAccounts();
                    this.accounts.Accounts = [];
                }
            }
        });
    }

    async onSave(): Promise<void> {
        if (await this.updateUser()) {
            this._dialogRef.close();
        } else {
            alert('Error saving this user');
        }
    }

    onCancel(): void {
        this._dialogRef.close();
    }

    async onDelete(): Promise<void> {
        if (await this.deleteUser()) {
            this._dialogRef.close();
        } else {
            // error
            alert('Error deleting this user');
        }
    }

    onSelectAccounts(): void {
        // save current user first if needed

        let accountData: UserAccountData = new UserAccountData();
        accountData.accounts = this.accounts;
        accountData.user = this.currentUser;

        let config: MatDialogConfig = new MatDialogConfig();
        config.data = accountData;
        config.height = "650px";
        config.width = "600px";

        const accountsDialogRef: MatDialogRef<UserAccountsComponent> =
            this._accountsDialog.open(UserAccountsComponent, config);

        accountsDialogRef.afterClosed().subscribe({ next: () => {} });
    }

    private async updateUser(): Promise<boolean> {
        let success: boolean = false;
        if (this.action === 'Edit') {
            success = await this._userService.updateUser(this.currentUser);
        } else if (this.action === 'Add') {
            success = await this._userService.addUser(this.currentUser);
        } else {
            // error
        }

        return success;
    }

    private async deleteUser(): Promise<boolean> {
        return await this._userService.deleteUser(this.originalUser);
    }

    private copyUser(u: User): User {
        let newUser: User = new User();
        newUser.CompanyName = u.CompanyName;
        newUser.Enabled = u.Enabled;
        newUser.FirstName = u.FirstName;
        newUser.Id = u.Id;
        newUser.LastName = u.LastName;
        newUser.Role = u.Role;

        return newUser;
    }

    private isValidUserId(userId: string): boolean {
        if (userId === null || userId === '') {
            return false;
        }

        return UserEditComponent.userIdValidator.test(userId);
    }
}
