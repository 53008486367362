import { Component, OnInit, inject } from '@angular/core';

import { PageDetail } from '@models/page-detail';
import { PageService } from '@services/page.service';
import { PageSummary } from '@models/page-summary';
import { LanguageSyncService } from '@services/language-sync.service';
import { Language } from '@models/language';

@Component({
  selector: 'cp-page-base',
  templateUrl: './page-base.component.html',
  styleUrls: ['./page-base.component.less']
})
export class PageBaseComponent implements OnInit {
    pageTitle: string;
    details: PageDetail[] = [];

    protected detailPageId: string = '';

    protected _pageService: PageService = inject(PageService);
    protected _languageSyncService: LanguageSyncService = inject (LanguageSyncService);

    constructor() {
        this._languageSyncService.getLanguage().subscribe(
            (_: Language) => this.initialize()
        );
    }

    ngOnInit(): void {
        this.initialize();
    }

    protected getDetails(pageId: string): void {
        this._pageService.getPage(pageId).subscribe({
            next: (summary: PageSummary) => {
                this.pageTitle = summary.Title;
                this.details = summary.Details;
            }
        });
    }

    protected initialize(): void {
        this.getDetails(this.detailPageId);
    }
}
