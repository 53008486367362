import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { SortService } from './sort.service';

@Component({
    selector: '[sortable-column]',
    templateUrl: './sortable-column.component.html',
    styleUrls: ['./sortable-column.component.less']
})
export class SortableColumnComponent implements OnInit, OnDestroy {
    private columnSortedSubscription: Subscription;

    // tslint:disable-next-line:no-input-rename
    @Input("sortable-column") columnIndex: number;

    // tslint:disable-next-line:no-input-rename
    @Input("table-id") tableId: number;

    // @Input("sort-direction") sortDirection: string = "";
    sortDirection: string = "";

    // tslint:disable-next-line:no-input-rename
    @Input("query-id") queryId: string;

    constructor(private sortService: SortService) {}

    @HostListener("click")
    sort() {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
        this.sortService.columnSorted({ tableId: this.tableId, sortColumn: this.columnIndex, sortDirection: this.sortDirection,
            queryId: this.queryId });
    }

    ngOnInit(): void {
        this.columnSortedSubscription = this.sortService.columnSorted$.subscribe({
            next: (event) => {
                if (this.tableId !== event.tableId || this.columnIndex !== event.sortColumn) {
                    this.sortDirection = "";
                }
            }
        });
    }

    ngOnDestroy(): void {
        if (this.columnSortedSubscription) {
            this.columnSortedSubscription.unsubscribe();
        }
    }
}
