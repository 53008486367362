import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'cp-tile-button',
    templateUrl: './tile-button.component.html',
    styleUrls: ['./tile-button.component.less']
})
export class TileButtonComponent {
    @Input() height: number = 100;
    @Input('max-height') maxHeight: number;
    @Input('min-height') minHeight: number;
    @Input() width: number = 100;
    @Input('max-width') maxWidth: number;
    @Input('min-width') minWidth: number;
    @Input() color: string;
    @Input() backgroundColor: string;
    @Input() hoverColor: string;
    @Input() icon: string;
    @Input() disabled: boolean = false;
    @Input() disabledBackgroundColor: string;

    @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();

    get tileBackgroundColor(): string {
        if (this.disabled) {
            return this.disabledBackgroundColor;
        } else if (this.hover) {
            return this.hoverColor;
        }
        return this.backgroundColor;
    }

    hover: boolean = false;

    constructor() { }

    ngOnInit(): void {
        if (!this.hoverColor) {
            this.hoverColor = this.backgroundColor;
        }
    }

    buttonClicked(e: MouseEvent): void {
        if (!this.disabled) {
            if (e.button === 0) {
                this.buttonClick.emit();
            }
        }
    }

}
