import { Component } from '@angular/core';
import { FormFieldBase } from '@models/form-fields';

@Component({
  selector: 'cp-form-detail',
  templateUrl: './form-detail.component.html',
  styleUrls: ['./form-detail.component.less']
})
export class FormDetailComponent {
    formId: string;
    pageId: string;
    returnUrl: string;
    dataQueryId: string;
    autoRunDataQuery: boolean;
    isLoginForm: boolean;
    isAuthenticationForm: boolean;
    fields: FormFieldBase<any>[] = [];
}
