import { HttpErrorResponse } from '@angular/common/http';
import { OnInit, AfterContentInit, AfterViewInit, Component, Input, ElementRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription } from 'rxjs';

import { DataQueryResult } from '@models/data-query-result';
import { FormFieldBase, FormFieldCheckIdentityButton, FormFieldSubmitAndClearButtons, FormFieldSubmitButton } from '@models/form-fields';
import { ModalDataQueryConfigs } from '@models/modal-data-query-configs';
import { UserCustomerAccounts } from '@models/user-customer-accounts';
import { IValidatedUser } from '@models/validated-user';
import { FormService } from '@services/form.service';
import { GlobalService } from '@services/global.service';
import { ModalService } from '@services/modal.service';
import { UserService } from '@services/user.service';
import { environment } from '@environment';
import { ModalResultsDialogComponent } from 'src/app/data-query/modal-results-dialog/modal-results-dialog.component';

@Component({
    selector: 'cp-form-base',
    templateUrl: './form-base.component.html',
    styleUrls: ['./form-base.component.less']
})
export class FormBaseComponent implements OnInit, AfterViewInit, AfterContentInit {
    @Input() fields: FormFieldBase<any>[] = [];
    @Input() formId: string;
    @Input() pageId: string;
    @Input() returnUrl: string = '';
    @Input() dataQueryId: string = '';
    @Input() autoRunDataQuery: boolean = false;
    @Input() isLoginForm: boolean = false;
    @Input() isAuthenticationForm: boolean = false;

    form: UntypedFormGroup;
    payLoad: string = '';
    responseMessage: string = '';
    dataQueryResults: DataQueryResult[] = [];
    private captchaSucceeded: boolean = false;
    private _captchaSubscription: Subscription;

    private responseSuccess: boolean = false;
    private responseFailure: boolean = false;
    private responseEmailConfirm: boolean = false;
    private responseValidationLink: boolean = false;
    // private domainList: InternalDomain[] = [];
    busy: boolean = false;

    get localImageLocation(): string {
        return environment.LocalImageLocation;
    }

    successModalId: string = 'successModal';

    formSubmitAttempt: boolean = false;

    constructor(
        private _formService: FormService,
        private _userService: UserService,
        private _globalService: GlobalService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _el: ElementRef,
        private _modalService: ModalService,
        private _dialog: MatDialog,
        private _sanitizer: DomSanitizer,
        // private _reCaptchaService: ReCaptchaV3Service
    ) {}

    ngOnInit() {
        this.form = this._formService.toFormGroup(this.fields);
        // if (this.returnUrl !== '') {
        //     sessionStorage.setItem('returnUrl', this.returnUrl);
        // }

        if (this.autoRunDataQuery && this.dataQueryId !== '') {
            this.processDataQuery();
        }

        // if (this.isLoginForm) {
        //     this.getDomainList();
        // }

        const user: IValidatedUser = this._userService.getCurrentUser();
        if (this.isAuthenticationForm && user !== null) {
            console.log('FormBase-ngOnInit');
            this.navigateToTargetPage({ userId: user.UserId });
        }
    }

    ngAfterViewInit(): void {
        this.payLoad = '';
    }

    ngAfterContentInit(): void {
        this.payLoad = '';
    }

    onRefreshDataQuery(): void {
        this.processDataQuery();
    }

    // private getDomainList(): void {
    //     this._globalService.getDomainList().subscribe({
    //         next: (domainList: InternalDomain[]) => {
    //             this.domainList = domainList;
    //         }
    //     });
    // }

    private processDataQuery(): void {
        const formData: { [id: string]: string } = {};
        formData['objectId'] = this.pageId;
        formData['objectType'] = 'page';
        formData['LanguageId'] = this._globalService.getLanguage();

        this._route.snapshot.queryParamMap.keys.forEach((key: string) => {
            formData['QueryString-' + key.toLowerCase()] = this._route.snapshot.queryParamMap.get(key);
        });

        const user: IValidatedUser = this._userService.getCurrentUser();
        if (user) {
            formData['loginContext'] = user.UserId;
        }

        this.dataQueryResults = [];
        this.submitDataQuery(formData);
    }

    disableForm(toBeDisabled: boolean): void {
        if (toBeDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    private handleCaptchaToken(token: string): void {
        // if (token && token !== '') {
            this.captchaSucceeded = true;
            this.submitForm();
        // } else {
        //     this.captchaSucceeded = false;
        // }
        // if (this._captchaSubscription) {
        //     this._captchaSubscription.unsubscribe();
        // }
    }

    async onSubmit(): Promise<void> {
        // if (this.form.contains('captchaToken')) {
        //     this._captchaSubscription = this._reCaptchaService.execute('submit').subscribe({
        //         next: (token) => this.handleCaptchaToken(token)
        //     });
        // } else {
            this.handleCaptchaToken('not required');
        // }
    }

    private async submitForm(): Promise<void> {
        this.validateForm();
        if (this.form.valid) {
            const formData = this.form.value;
            const keys: string[] = Object.keys(formData);
            keys.forEach((key) => {
                if (!formData[key] || formData[key] === '' || key === 'captcha' || key === '') {
                    delete formData[key];
                } else if (typeof formData[key] !== 'string') {
                    if (formData[key].formatted) {
                        formData[key] = formData[key].formatted;
                    }
                }
            });

            formData['objectId'] = this.pageId;
            formData['objectType'] = 'page';
            formData['LanguageId'] = this._globalService.getLanguage();

            const user: IValidatedUser = this._userService.getCurrentUser();
            if (user) {
                formData['loginContext'] = user.UserId;
            }
            const referringObjectId = this._route.snapshot.queryParams['referringid'];
            if (referringObjectId) {
                formData['referringObjectId'] = referringObjectId;
            }
            const referringObjectType = this._route.snapshot.queryParams['referringtype'];
            if (referringObjectType) {
                formData['referringObjectType'] = referringObjectType;
            }

            switch (this.formId) {
                case 'LoginForm':
                    this.loginUser(formData);
                    break;
                default:
                    if (this.isLoginForm) {
                        console.log('loginForm');
                        if (await this._userService.isInternalDomain(formData['UserId'])) {
                            console.log('loginForm - isInternal');
                            if (!this._userService.isAuthenticated()) {
                                console.log('loginForm - is not authenticated');
                                await this._userService.loginInternalUser(formData["UserId"]);
                                // if (this._userService.isAuthenticated()) {
                                //     this._router.navigate(['home']);
                                // }


                                // this._userService.loginInternalUser(formData["UserId"])
                                //     .subscribe((result: AuthenticationResult) => {
                                //         console.log('loginInternalUser success');
                                //         this._userService.setInternalAccount(result.account, result.idToken);
                                //         console.log('setInternalAccount -> navigate');
                                //         this._router.navigate(['home']);
                                        // this._authService.instance.setActiveAccount(result.account);
                                        // this.internalToken = response.idToken;

                                        // if (this.internalToken && this.internalToken !== '') {
                                        //     console.log('acquireTokenPopup success/internalToke = ' + this.internalToken);
                                        //     this.createValidatedUser().then();
                                        // }
                                //     }
                                // );
                                // await firstValueFrom(this._userService.loginInternalUser(formData["UserId"]));
                                // await this._userService.loginAuthenticatedUser();
                                // const currentUser: IValidatedUser = this._userService.getCurrentUser();
                                // if (currentUser) {
                                //     this._router.navigate(['home']);
                                //     // this.gotoValidationPage(currentUser);
                                // }
                            }
                        } else {
                            this.submitIdentityCheck(formData);
                        }
                    } else if (this.isAuthenticationForm) {
                        this.verifyIdentity(formData);
                    } else if (this.dataQueryId && this.dataQueryId !== '') {
                        this.submitDataQuery(formData);
                    } else {
                        this.submitData(formData);
                    }
            }

            this.payLoad = JSON.stringify(this.form.value);
        }
    }

    private gotoValidationPage(user: IValidatedUser): void {
        // this.returnUrl = sessionStorage.getItem('returnUrl');
        // if (this.returnUrl) {
        //     sessionStorage.removeItem('returnUrl');
        // }

        // if (this.returnUrl && this.returnUrl !== '' && this.returnUrl !== '/') {
            this._userService.getCustomerAccountOptions('').subscribe({
                next: (accounts: UserCustomerAccounts) => {
                    if (accounts && accounts.Accounts && accounts.Accounts.length === 1) {
                        this._userService.setCustomerAccount(accounts.Accounts[0], accounts.CustomerAccountSelectedPageId);
                    }
                    // this._router.navigate(['home']);MLJ
                },
                error: (error: any) => {
                    this.handleError(error);
                }
            });
        // } else {
        //     this.navigateToTargetPage({ userId: user.UserId });
        // }
    }

    // private isInternalDomain(emailAddress: string): boolean {
    //     if (!this.domainList.length) {
    //         this.getDomainList();
    //     }

    //     emailAddress = emailAddress.toLowerCase();
    //     const domainList: InternalDomain[] = this.domainList.filter(
    //         (domain: InternalDomain) => {
    //             if (emailAddress.endsWith('@' + domain.Id.toLowerCase())) {
    //                 return domain;
    //             }
    //             return null;
    //         }
    //     );

    //     return domainList.length > 0;
    // }

    private validateForm(): void {
        this.formSubmitAttempt = true;
    }

    private submitIdentityCheck(data: any): void {
        this._formService.submitIdentityCheck(data).subscribe();
        console.log('submitIdentityCheck');
        this.navigateToTargetPage({ userId: data.UserId });
    }

    private async verifyIdentity(data: any): Promise<void> {
        const userId: string = this._route.snapshot.queryParamMap.get('userId');
        data['UserId'] = userId;
        let isInternalUser = await this._userService.isInternalDomain(userId);
        this._formService.submitVerifyIdentity(data).subscribe({
            next: (result: IValidatedUser) => {
                if (result) {
                    this.responseSuccess = true;

                    if (!isInternalUser) {
                        this._userService.setCurrentUser(result);
                    }

                    this.gotoValidationPage(result);
                } else {
                    this.responseFailure = true;
                    this._userService.setCurrentUser(null);
                    this.responseMessage = this.getFailureMessage();
                    this.formSubmitAttempt = false;
                }
            },
            error: (error: HttpErrorResponse) => {
                if (error.status === 500) {
                    this.responseFailure = true;
                    this._userService.setCurrentUser(null);
                    this.responseMessage = error.statusText;
                    this.formSubmitAttempt = false;
                } else {
                    this.handleError(error);
                }
            }
        });
    }

    private submitData(data: any): void {
        this._formService.submitData(this.formId, data).subscribe({
            next: (result: boolean) => {
                if (result) {
                    this.responseSuccess = true;
                    this.responseMessage = this.getSuccessMessage();
                    this._modalService.open(this.successModalId);
                } else {
                    this.responseFailure = true;
                    this.responseMessage = this.getFailureMessage();
                    this.formSubmitAttempt = false;
                }
            },
            error: (error: HttpErrorResponse) => {
                this.handleError(error);
            }
        });
    }

    private submitDataQuery(data: any) {
        this.busy = true;
        this.dataQueryResults = [];
        this._formService.submitDataQuery(this.formId, this.dataQueryId, data).subscribe({
            next: (results: any) => {
                this.dataQueryResults = results;
                this.busy = false;
            },
            error: (error: HttpErrorResponse) => {
                this.busy = false;
                this.handleError(error);
            }
        });
    }

    private loginUser(data: any): void {
        sessionStorage.removeItem('returnUrl');
        this._userService.loginUser(data).subscribe({
            next: (response: boolean) => {
                if (response) {
                    if (this.returnUrl !== '') {
                        console.log('loginUser');
                        this._router.navigate([this.returnUrl]);
                    } else {
                        this.responseSuccess = true;
                        this.responseMessage = this.getSuccessMessage();
                    }
                } else {
                    this.responseFailure = true;
                    this.responseMessage = this.getFailureMessage();
                }
            },
            error: (error: HttpErrorResponse) => {
                if (error.status === 401) {
                    if (error.statusText.toLowerCase() !== 'ok') {
                        this.responseMessage = error.statusText;
                    } else {
                        this.responseFailure = true;
                        this.responseMessage = this.getFailureMessage();
                    }
                } else {
                    this.responseFailure = true;
                    this.responseMessage = this.getFailureMessage();
                }
            }
        });
    }

    protected getFailedIdentityValidationMessage(): string {
        if (this.fields && this.fields.length > 0) {
            const failureMessage: FormFieldBase<any>[] = this.fields.filter(
                (f: FormFieldBase<any>) => {
                    if (f.controlType === 'msgValidationLink') {
                        return f;
                    }
                    return null;
                }
            );

            if (failureMessage && failureMessage.length > 0) {
                return failureMessage[0].value;
            }
        }

        return '';
    }

    private getFailureMessage(): string {
        if (this.fields && this.fields.length > 0) {
            const failureMessage: FormFieldBase<any>[] = this.fields.filter(
                (f: FormFieldBase<any>) => {
                    if (f.controlType === 'msgFailure') {
                        return f;
                    }
                    return  null;
                }
            );

            if (failureMessage && failureMessage.length > 0) {
                return failureMessage[0].value;
            }
        }

        return '';
    }

    private getSuccessMessage(): string {
        if (this.fields && this.fields.length > 0) {
            const message: FormFieldBase<any>[] = this.fields.filter(
                (f: FormFieldBase<any>) => {
                    if (f.controlType === 'msgSuccess') {
                        return f;
                    }
                    return null;
                }
            );

            if (message && message.length > 0) {
                return message[0].value;
            }
        }

        return '';
    }

    onClear(): void {
        if (this.form.controls['captcha']) {
            this.form.controls['captcha'].reset();
        }
        this.form.reset();
        this.fields.forEach((f: FormFieldBase<any>) => {
            f.resetToDefault();
        });
    }

    handleCaptchaReset(): void {
        this.captchaSucceeded = false;
    }

    onCaptcha(): void {
        this.captchaSucceeded = true;
    }

    getMessageStyle() {
        if (this.responseFailure) {
            return '#cc0000';
        }

        return 'inherited';
    }

    onModalClosed(): void {
        this.formSubmitAttempt = false;

        const returnUrl: string = sessionStorage.getItem('returnUrl');

        if (returnUrl) {
            sessionStorage.removeItem('returnUrl');
        }

        this.form.reset();

        if (returnUrl && returnUrl !== '' && returnUrl !== '/') {
            console.log('onModalClosed1');
            this._router.navigate([this.returnUrl]);
        } else {
            console.log('onModalClosed2');
            this.navigateToTargetPage(null);
        }
    }

    private navigateToTargetPage(additionalData: Params | null): void {
        const successTarget: FormFieldBase<any>[] = this.fields.filter(
            (f: FormFieldBase<any>) => {
                if (f.controlType === 'buttonSubmit' ||
                    f.controlType === 'buttonSubmitAndClear' ||
                    f.controlType === 'buttonCheckIdentity'
                ) {
                    return f;
                }
                return null;
            }
        );

        if (successTarget && successTarget.length > 0) {
            const targetPageId: string = this.getTargetPage(successTarget[0]);

            let params: Params;
            if (additionalData) {
                params = additionalData;
            } else {
                params = { dt: 1 };
            }

            console.log('navigateToTargetPage');
            this._router.navigate(
                [targetPageId],
                { queryParams: params }
            );
        }
    }

    private getTargetPage(field: FormFieldBase<any>): string {
        if (field.controlType === 'buttonSubmit') {
            return (field as FormFieldSubmitButton).targetPageId;
        } else if (field.controlType === 'buttonSubmitAndClear') {
            return (field as FormFieldSubmitAndClearButtons).targetPageId;
        } else if (field.controlType === 'buttonCheckIdentity') {
            return (field as FormFieldCheckIdentityButton).targetPageId;
        }

        return '';
    }

    private handleError(error: any): void {
        console.error('An error occurred', error);
    }

    onShowResultsInModal(configs: ModalDataQueryConfigs): void {
        const dialogRef: MatDialogRef<ModalResultsDialogComponent> =
            this._dialog.open(ModalResultsDialogComponent, {
                data: configs,
                width: 'auto',
            });

        dialogRef.afterClosed().subscribe({
            next: () => {
                // this.processDataQuery();
            }
        });
    }

}
