import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'cp-page-description-heading',
    templateUrl: './page-description-heading.component.html',
    styleUrls: ['./page-description-heading.component.less']
})
export class PageDescriptionHeadingComponent {
    heading: SafeHtml;

    constructor(private sanitizer: DomSanitizer) {}

    setHeading(heading: string): void {
        this.heading = this.sanitizer.bypassSecurityTrustHtml(heading);
    }
}
