import { Component, OnInit } from '@angular/core';

import { PageBaseComponent } from '../base/page-base/page-base.component';

@Component({
  selector: 'cp-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent extends PageBaseComponent implements OnInit {
    protected override detailPageId: string = 'login';

    // override ngOnInit(): void {
    //     // HACK because of bug in MSAL where value doesn't get cleared upon sign-out:
    //     // sessionStorage.removeItem('msal.interaction.status');
    //     this.getDetails('login');
    // }
}
