<p *ngIf="titlePresent && (descriptionPresent || otherPresent)" style="padding-bottom: 10px !important;">
    <span style="font-weight: bold" [innerHTML]="title"></span>&nbsp;
    <span *ngIf="descriptionPresent" [innerHTML]="description"><br /><br/></span>
    <span *ngIf="otherPresent" [innerHTML]="other"></span>
</p>
<span *ngIf="titlePresent && !descriptionPresent && !otherPresent" style="padding-bottom: 10px !important;">
    <span style="font-weight: bold" [innerHTML]="title"></span>
</span>
<p *ngIf="!titlePresent && descriptionPresent" [innerHTML]="description" style="padding-bottom: 10px !important;"><br /><br/></p>
<span *ngIf="!titlePresent && otherPresent" [innerHTML]="other"></span>
