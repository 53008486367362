export const environment = {
    production: true,

    ApiBase: "./CustomerPortal.API/",

    ImageLocation: "assets/",
    LocalImageLocation: "assets/",

    DocumentLocation: "assets/documents/",

    System: "BMS"
};
