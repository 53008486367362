import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb } from '@models/breadcrumb';
import { Language } from '@models/language';
import { PageDetail } from '@models/page-detail';
import { PageSummary } from '@models/page-summary';
import { LanguageSyncService } from '@services/language-sync.service';
import { PageService } from '@services/page.service';

@Component({
  selector: 'cp-dynamic-page',
  templateUrl: './dynamic-page.component.html',
  styleUrls: ['./dynamic-page.component.less']
})
export class DynamicPageComponent implements OnInit {
    pageTitle: string;
    details: PageDetail[] = [];
    breadcrumbs: Breadcrumb[] =
        [
            { label: 'Home', route: '/home' },
            { label: 'Invoices', route: '' }
        ];

    protected detailPageId: string = '';

    protected _pageService: PageService = inject(PageService);
    protected _languageSyncService: LanguageSyncService = inject (LanguageSyncService);

    constructor(private _activatedRoute: ActivatedRoute) {
        this._languageSyncService.getLanguage().subscribe(
            (_: Language) => this.initialize()
        );
    }

    ngOnInit(): void {
        this._activatedRoute.data.subscribe(d => {
            this.detailPageId = d['details'];
            // this.breadcrumbs = d['breadcrumbs'];
            // this.pageTitle = d['title'];
        });
        this.initialize();
    }

    protected getDetails(pageId: string): void {
        this._pageService.getPage(pageId).subscribe({
            next: (summary: PageSummary) => {
                this.pageTitle = summary.Title;
                this.details = summary.Details;
            }
        });
    }

    protected initialize(): void {
        this.getDetails(this.detailPageId);
    }
}
