import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Route } from '@angular/router';
import { environment } from '@environment';
import { SetupInfo } from '@models/setup-info';
import { map, Observable } from 'rxjs';
import { DynamicPageComponent } from '../dynamic-page/dynamic-page.component';

@Injectable({
    providedIn: 'root'
})
export class RouteConfigService {
    private baseUrl: string = environment.ApiBase + 'home/';

    constructor(private _http: HttpClient) { }

    getRoutes(): Observable<Route[]> {
        let params: HttpParams = new HttpParams();
        params = params.append('language', 'en-us');

        return this._http.get<SetupInfo[]>(this.baseUrl + "setup-info", { params: params })
            .pipe(
                map((response: SetupInfo[]) => {
                    let routes: Route[] = [];
                    response.forEach(item => {
                        routes.push({path: item.route, component: DynamicPageComponent, data: { details: item.details }});
                        // newRoutes.push({path: item.pageRoute, component: DynamicPageComponent, data: { title: item.pageTitle, details: item.details, breadCrumbs: item.breadCrumbs }, canActivate: [ isLoggedIn, hasAccountSelected ]});
                    });
                    return routes;
                })
            );
    }
}
