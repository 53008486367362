import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { PermissionService } from './permissions.service';
import { map } from 'rxjs';

export const isLoggedInWithAccount: CanActivateFn = (route, state) => {
    const _permissions: PermissionService = inject(PermissionService);
    const _router: Router = inject(Router);

    return _permissions.isLoggedInAndAccountSelected().pipe(
        map((result: boolean) => {
            if (result) {
                return true;
            } else {
                _router.navigate([ 'login' ]);
                return false;
            }
        })
    )
};

export const hasAccountSelected: CanActivateFn = (route, state) => {
    const _permissions: PermissionService = inject(PermissionService);
    const _router: Router = inject(Router);

    if (_permissions.isAccountSelected()) {
        return true;
    } else {
        _router.navigate([ 'accounts' ]);
        return false;
    }
};

export const isLoggedIn: CanActivateFn = (route, state) => {
    const _permissions: PermissionService = inject(PermissionService);
    const _router: Router = inject(Router);

    return _permissions.isLoggedIn().pipe(
        map((result: boolean) => {
            if (result) {
                return true;
            } else {
                _router.navigate([ 'login' ]);
                return false;
            }
        })
    )
};
