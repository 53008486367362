<div class="banner-container">
    <img *ngIf="info && info.FooterBanner !== ''" class="footer-banner" [src]="'assets/' + (info !== null ? info.FooterBanner : '')">
</div>
<div class="footer-frame">
    <div class="footer-container">
        <div>
            <div class="site-link">
                &nbsp;<a [href]="info?.FooterLinkUrl">{{info?.FooterLink}}</a>
            </div>
            <div class="copyright">
                &copy;{{currentYear}} {{info?.FooterCopyright}}
            </div>
        </div>
    </div>
</div>
