import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, firstValueFrom } from 'rxjs';

import { environment } from '@environment';
import { UserService } from './user.service';
import { GlobalService } from './global.service';
import { CustomerAccount } from '@models/customer-account';
import { FormFieldBase } from '@models/form-fields';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FormFieldDefinition } from '@models/form-field-definition';

@Injectable({
  providedIn: 'root'
})
export class FormService {
    private baseUrl: string = environment.ApiBase + "form/";
    private dataQueryUrl: string = environment.ApiBase + "dataQuery/";
    private fileUrl: string = environment.ApiBase + "file";

    constructor(private http: HttpClient, private userService: UserService, private globalService: GlobalService) { }

    getForm(formId): Observable<FormFieldDefinition[]> {
        let params: HttpParams = new HttpParams();
        params = params.append('language', this.globalService.getLanguageCode());

        // const currentUser: ValidatedUser = this.userService.getCurrentUser();
        // if (currentUser !== undefined && currentUser !== null) {
        //     params = params.append('userId', currentUser.UserId); // TODO: NOT NEEDED - USE JWT TOKEN VALUE
        // }

        return this.http.get<FormFieldDefinition[]>(`${this.baseUrl}${formId}`, {
            params: params,
        });

    }

    submitData(formId: string, data: any): Observable<Object> {
        const headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/json" });

        return this.http.post(this.baseUrl + formId, data, { headers: headers });
    }

    submitIdentityCheck(data: any): Observable<Object> {
        const headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/json" });

        return this.http.post(this.baseUrl + "identity", data, { headers: headers });
    }

    submitVerifyIdentity(data: any): Observable<Object> {
        const headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/json" });

        return this.http.post(this.baseUrl + "verify-identity", data, { headers: headers });
    }

    submitDataQuery(formId: string, dataQueryId: string, data: any): Observable<Object> {
        const headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/json" });

        data.LanguageId = this.globalService.getLanguageCode();

        const account: CustomerAccount = this.userService.getCustomerAccount();
        if (account) {
            data["custAccount"] = account.Id;
        }

        return this.http.post(`${this.dataQueryUrl}${formId}/${dataQueryId}`, data, { headers: headers });
    }

    executeDataQuery(dataQueryString: string, data: any): Observable<Object> {
        const headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/json" });
        if (!data) {
            data = {};
        }

        data.LanguageId = this.globalService.getLanguageCode();
        data.dataQuery = dataQueryString;

        const account: CustomerAccount = this.userService.getCustomerAccount();
        if (account) {
            data["custAccount"] = account.Id;
        }

        return this.http.post(`${this.dataQueryUrl}action`, data, { headers: headers });
    }

    uploadFile(formId: string, data: FormData): Observable<Object> {
        return this.http.post(this.baseUrl + "upload/file", data);
    }

    toFormGroup(fields: FormFieldBase<any>[]) {
        const group: any = [];
        let idx: number = 0;

        fields.forEach(field => {
            const validators: any[] = [];

            if (field.required) {
                validators.push(Validators.required);
            }

            if (field.pattern) {
                validators.push(Validators.pattern(field.pattern));
            }

            if (field.controlType === "date") {
                field.value = new Date();
            }

            if (!field.key || field.key === "") {
                field.key = `${field.controlType}_${idx}`;
            }

            if (field.controlType.substring(0, 6) === "button") {
                field.value = field.label;
            }

            group[field.key] = validators.length > 0
                ? new UntypedFormControl(field.value || "", validators)
                : new UntypedFormControl(field.value || "");

            idx++;
        });

        return new UntypedFormGroup(group);
    }

    // i.e. getFile("k2-00007.gif", "DVaultThumbnail", "Image")
    getFilestream(fileName: string, fileType: string, streamType: string, asBytes: boolean = false): Observable<any> {
        const params: HttpParams = new HttpParams({ fromObject: {
            "fileName": fileName,
            "fileType": fileType,
            "streamType": streamType
        }});

        let headers: HttpHeaders;
        if (asBytes) {
            headers = new HttpHeaders({ "Content-Type": "application/octet-stream" });
            return this.http.get(`${this.fileUrl}`, { params: params, headers: headers });
        } else {
            return this.http.get(`${this.fileUrl}`, { params: params, responseType: "blob" });
        }
    }

    async getFilestreamExists(fileName: string, fileType: string, streamType: string): Promise<any> {
        const params: HttpParams = new HttpParams({ fromObject: {
            "fileName": fileName,
            "fileType": fileType,
            "streamType": streamType
        }});

        let headers: HttpHeaders;
        return firstValueFrom(this.http.get(`${this.fileUrl}/availability`, { params: params, headers: headers }));
    }
}

function validateDateRequired(control: UntypedFormControl) {
    return (control.value && control.value !== "") ? null : { validateDateRequired: { valid: false }};
}
