import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '@environment';
import { CustomerAccount } from '@models/customer-account';
import { UserCustomerAccounts } from '@models/user-customer-accounts';
import { GlobalService } from '@services/global.service';
import { LanguageSyncService } from '@services/language-sync.service';
import { UserService } from '@services/user.service';
import { BaseComponent } from 'src/app/base/base/base.component';

@Component({
    selector: 'cp-customer-account-selector',
    templateUrl: './customer-account-selector.component.html',
    styleUrls: ['./customer-account-selector.component.less']
})
export class CustomerAccountSelectorComponent extends BaseComponent implements OnInit
{
    protected _localImageLocation: string = environment.LocalImageLocation;

    busy: boolean = false;

    get showAccounts(): boolean {
        return true;
    }

    accounts: UserCustomerAccounts;

    searchHelpText: string;
    searchButtonTitle: string;

    toolTip: string;

    searchCriteria: string = '';
    pagedAccounts: CustomerAccount[][];
    currentPage: number = 0;
    maxPages: number = 0;
    noResultsFound: boolean = false;

    constructor(
        private userService: UserService,
        private router: Router,
        private _languageSyncService: LanguageSyncService
    ) {
        super();

        this.subscriptions.push(_languageSyncService.getLanguage().subscribe({ next: () => this.ngOnInit() }));
    }

    ngOnInit() {
        this.searchAccounts();
    }

    searchAccounts(): void {
        this.busy = true;
        this.noResultsFound = false;
        this.userService
            .getCustomerAccountOptions(this.searchCriteria)
            .subscribe({
                next: (accounts: UserCustomerAccounts) => {
                    this.busy = false;
                    this.accounts = accounts;

                    if (accounts && accounts.Accounts && accounts.Accounts.length > 0 ) {
                        if (accounts.Accounts.length === 1 && this.searchCriteria === '') {
                            this.pagedAccounts = [];
                            this.accountSelected(accounts.Accounts[0]);
                        } else {
                            let startIdx: number = 0;
                            let page: number = 0;
                            this.pagedAccounts = [];
                            do {
                                this.pagedAccounts[page] = accounts.Accounts.slice(startIdx, startIdx + 20);
                                startIdx += 20;
                                page++;
                            } while (startIdx < accounts.Accounts.length);
                            this.maxPages = page;
                            this.currentPage = 0;
                        }
                    } else {
                        this.pagedAccounts = [];
                        this.maxPages = 0;
                        this.noResultsFound = true;
                    }
                },
                error: (error: any) => {
                    this.busy = false;
                    this.handleError(error);
                }
            });
    }

    accountSelected(account: CustomerAccount): void {
        let currentAccount: CustomerAccount = this.userService.getCustomerAccount();
        if ((currentAccount?.Id !== account.Id)) {
            this.userService.setCustomerAccount(account, this.accounts.CustomerAccountSelectedPageId);
        }
        this.router.navigate(['home']);
    }

    setPage(page: number): void {
        this.currentPage = page;
    }

    incrementPage(increment: number): void {
        this.currentPage += increment;
        if (this.currentPage > this.maxPages - 1) {
            this.currentPage = this.maxPages - 1;
        } else if (this.currentPage < 0) {
            this.currentPage = 0;
        }
    }
}
