<div class="page-detail">
    <div class="page-detail-content">
        <div class="page-container">
            <span class="page-title">{{userMaintenanceInfo?.MaintenanceTitle}}</span>
            <cp-breadcrumb [breadcrumbs]="breadcrumbs"></cp-breadcrumb>
        </div>
        <div class="user-maintenance">
            <form>
                <div class="formTable">
                    <div class="tooltip">
                        <input class="formField" [(ngModel)]="searchTerm" name="searchCriteria">
                        <span class="tooltiptext">{{userMaintenanceInfo?.MaintenanceSearchHelp}}</span>
                    </div>
                </div>
                <input type="submit" (click)="onSearch()" value="Submit" placeholder="Find a user"/>
                <span *ngIf="noResultsFound" style="color: red; padding-left: 20px;">{{userMaintenanceInfo?.MaintenanceNoResults}}</span>
                <div style="clear: both; height: 10px; display: block;"></div>
            </form>
            <p class="resultsHeading"><span>{{userMaintenanceInfo?.MaintenanceUsersHeading}}</span></p>
            <table mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="userId">
                    <th mat-header-cell *matHeaderCellDef> {{userMaintenanceInfo?.MaintenanceEmailHeading}} </th>
                    <td mat-cell *matCellDef="let user">
                        <a *ngIf="isEditable(user)" href="javascript:void(0)" (click)="edituser(user, $event)" style="text-decoration: underline; color: #999999">{{ user.Id }}</a>
                        <span *ngIf="!isEditable(user)" style="color: #999999">{{ user.Id }}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="firstName">
                    <th mat-header-cell *matHeaderCellDef> {{userMaintenanceInfo?.MaintenanceFirstNameHeading}} </th>
                    <td mat-cell *matCellDef="let user">{{ user.FirstName }}</td>
                </ng-container>
                <ng-container matColumnDef="lastName">
                    <th mat-header-cell *matHeaderCellDef> {{userMaintenanceInfo?.MaintenanceLastNameHeading}} </th>
                    <td mat-cell *matCellDef="let user">{{ user.LastName }}</td>
                </ng-container>
                <ng-container matColumnDef="company">
                    <th mat-header-cell *matHeaderCellDef> {{userMaintenanceInfo?.MaintenanceCompanyHeading}} </th>
                    <td mat-cell *matCellDef="let user">{{ user.CompanyName }}</td>
                </ng-container>
                <ng-container matColumnDef="role">
                    <th mat-header-cell *matHeaderCellDef> {{userMaintenanceInfo?.MaintenanceRoleHeading}} </th>
                    <td mat-cell *matCellDef="let user">{{ user.Role }}</td>
                </ng-container>
                <ng-container matColumnDef="enabled">
                    <th mat-header-cell *matHeaderCellDef> {{userMaintenanceInfo?.MaintenanceEnabledHeading}} </th>
                    <td mat-cell *matCellDef="let user" style="text-align: center">
                        <mat-icon *ngIf="user.Enabled" class="user-check">check_box</mat-icon>
                        <mat-icon *ngIf="!user.Enabled" class="user-check">check_box_outline_blank</mat-icon>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true" class="gridRowHeading"></tr>
                <tr mat-row *matRowDef="let user; columns: displayColumns"
                    class="list-data-row"
                    style="height: auto;"
                    [style.cursor]="isEditable(user) ? 'pointer' : 'default'"
                    (click)="selectUser(user)"
                    (dblclick)="edituser(user, $event)">
                </tr>
            </table>
            <mat-paginator [hidePageSize]="true" [pageSize]="20" showFirstLastButtons="true"></mat-paginator>
            <div>
                <input type="button" (click)="addUser()" value="{{userMaintenanceInfo?.MaintenanceAddUserButton}}" />
            </div>
        </div>
    </div>
</div>
