<div>
  <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="formTable">
          <div *ngIf="responseMessage && responseMessage !== ''" class="formRow" [ngStyle]="{ 'color': getMessageStyle() }">
              <strong>{{responseMessage}}</strong><br/>
          </div>
          <div *ngFor="let field of fields" class="formRow">
              <cp-form-field *ngIf="field.controlType==='buttonSubmitAndClear'" [field]="field" [form]="form" [submitAttempt]="formSubmitAttempt" (clearForm)="onClear()"></cp-form-field>
              <cp-form-field *ngIf="field.controlType==='buttonAttachFile' || field.controlType==='buttonAttachImage'" [field]="field" [form]="form" [submitAttempt]="formSubmitAttempt" (uploadingFile)="disableForm($event)"></cp-form-field>
              <cp-form-field *ngIf="field.controlType!=='buttonSubmitAndClear' && field.controlType!=='buttonAttachFile' && field.controlType!=='buttonAttachImage' && field.controlType!=='msgFailure'"
                  [field]="field" [form]="form" [submitAttempt]="formSubmitAttempt"></cp-form-field>
          </div>
      </div>
  </form>
  <img *ngIf="busy" src="{{localImageLocation}}ajax-loader.gif" class="form-busy" alt="loading">
  <cp-data-query-results [data]="dataQueryResults" (refreshPage)="onRefreshDataQuery()" (showModalResults)="onShowResultsInModal($event)"></cp-data-query-results>
  <cp-modal title="Confirmation" [blocking]="false" [modalId]="successModalId" (closed)="onModalClosed()">
      <div style="text-align: center">{{responseMessage}}</div>
  </cp-modal>
</div>
