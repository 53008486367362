import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DataQueryResult } from '@models/data-query-result';
import { ModalDataQueryConfigs } from '@models/modal-data-query-configs';

@Component({
    selector: 'cp-modal-results-dialog',
    templateUrl: './modal-results-dialog.component.html',
    styleUrls: ['./modal-results-dialog.component.less']
})
export class ModalResultsDialogComponent implements OnInit {
    dataQueryResults: DataQueryResult[] = [];
    title: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ModalDataQueryConfigs,
        private _dialogRef: MatDialogRef<ModalResultsDialogComponent>
    ) { }

    ngOnInit(): void {
        if (this.data) {
            this.title = this.data.title;
            this.dataQueryResults = this.data.dataQueryResults;
        }
    }

    onRefreshPage(): void {
        this._dialogRef.close();
    }
}
