import { AfterViewInit, Component, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { CustomerAccount } from '@models/customer-account';
import { User } from '@models/user';
import { UserCustomerAccounts } from '@models/user-customer-accounts';
import { UserService } from '@services/user.service';

export class UserAccountData {
    user: User;
    accounts: UserCustomerAccounts;
}

@Component({
  selector: 'cp-user-accounts',
  templateUrl: './user-accounts.component.html',
  styleUrls: ['./user-accounts.component.less']
})
export class UserAccountsComponent implements OnInit, AfterViewInit {
    displayColumns: string[] = ['accountNumber', 'accountName', 'accountLocation'];

    user: User;
    accounts: UserCustomerAccounts;
    availableAccounts: CustomerAccount[] = [];
    searchCriteria: string;

    currentSelected: CustomerAccount;
    availableSelected: CustomerAccount;

    currentDataSource: MatTableDataSource<CustomerAccount>;
    availableDataSource: MatTableDataSource<CustomerAccount>;

    @ViewChildren(MatPaginator) paginators = new QueryList<MatPaginator>();

    get UserFullName(): string {
        if (this.user) {
            return this.user.FirstName + ' ' + this.user.LastName;
        }
        return '';
    }

    get currentAccountSelected(): boolean {
        return (this.currentSelected !== undefined && this.currentSelected !== null);
    }

    get availableAccountSelected(): boolean {
        return (this.availableSelected !== undefined && this.availableSelected !== null);
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: UserAccountData,
        private _userService: UserService,
        private _dialogRef: MatDialogRef<UserAccountsComponent>)
    {
        this.user = data.user;
        this.accounts = data.accounts;

    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this._userService.getCustomerAccountOptions('').subscribe({
            next: (availableAccounts: UserCustomerAccounts) => {
                this.availableAccounts = availableAccounts.Accounts;

                this.currentDataSource = new MatTableDataSource();
                if (this.accounts) {
                    if (!this.accounts.Accounts) {
                        this.accounts.Accounts = [];
                    }
                    this.currentDataSource.data = this.accounts.Accounts.slice();
                }
                this.availableDataSource = new MatTableDataSource(this.availableAccounts);

                this.setupDataSources();
            }
        });

        // this.setupDataSources();
    }

    removeFromCurrent(account: CustomerAccount): void {
        if (!account) {
            account = this.currentSelected;
        }
        var idx: number = this.accounts.Accounts.indexOf(account);
        this.accounts.Accounts.splice(idx, 1);
        this.currentSelected = null;
        this.setupDataSources();
    }

    addToCurrent(account: CustomerAccount | null): void {
        if (!account) {
            account = this.availableSelected;
        }
        if (!this.accounts.Accounts) {
            this.accounts.Accounts = [];
        }

        if (!this.accounts.Accounts.find((a => a.Id === account.Id))) {
            this.accounts.Accounts.push(account);
        }
        this.availableSelected = null;
        this.setupDataSources();
    }

    async onSave(): Promise<void> {
        if (await this._userService.updateUserAccounts(this.user, this.accounts.Accounts)) {
            this._dialogRef.close(this.accounts);
        } else {
            alert('Error updating user accounts');
        }
    }

    onCancel(): void {
        this._dialogRef.close();
        // TODO
    }

    toggleCurrent(account: CustomerAccount): void {
        if (account) {
            if (this.currentSelected) {
                this.currentSelected = null;
            } else {
                this.currentSelected = account;
            }
        }
    }

    toggleAvailable(account: CustomerAccount): void {
        if (account) {
            if (this.availableSelected) {
                this.availableSelected = null;
            } else {
                this.availableSelected = account;
            }
        }
    }

    searchAccounts(): void {
        this._userService
            .getCustomerAccountOptions(this.searchCriteria)
            .subscribe({
                next: (accounts: UserCustomerAccounts) => {
                    this.availableAccounts = accounts.Accounts;
                    this.availableDataSource.data = this.availableAccounts;
                },
                error: (error: any) => {
                    // this.handleError(error);
                }
            });
    }

    private setupDataSources(): void {
        if (this.paginators) {
            if (this.accounts) {
                this.currentDataSource.data = this.accounts.Accounts;
                this.currentDataSource.paginator = this.paginators.toArray()[0];
                this.currentDataSource.paginator.length = this.accounts.Accounts.length;
            }

            if (this.availableAccounts) {
                this.availableDataSource.data = this.availableAccounts;
                this.availableDataSource.paginator = this.paginators.toArray()[1];
                this.availableDataSource.paginator.length = this.availableAccounts.length;
            }
        }
    }
}
