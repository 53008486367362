import { Component, ComponentRef, Input, OnChanges, SimpleChanges, ViewContainerRef } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { Form } from '@models/form';
import { PageDetail } from '@models/page-detail';
import { GlobalService } from '@services/global.service';
import { CustomerAccountSelectorComponent } from 'src/app/pages/customer-account-selector/customer-account-selector.component';
import { FormDetailComponent } from '../form-detail/form-detail.component';
import { PageDescriptionHeadingComponent } from '../page-description-heading/page-description-heading.component';
import { PageDescriptionComponent } from '../page-description/page-description.component';
import { Breadcrumb } from '@models/breadcrumb';
import { LanguageSyncService } from '@services/language-sync.service';

@Component({
  selector: 'cp-page-view',
  templateUrl: './page-view.component.html',
  styleUrls: ['./page-view.component.less']
})
export class PageViewComponent extends BaseComponent implements OnChanges {
    @Input() title: string;
    @Input() details: PageDetail[] = [];
    @Input() breadcrumbs: Breadcrumb[];

    private returnUrl: string = '';

    constructor(
        private globalService: GlobalService,
        private viewContainerRef: ViewContainerRef,
        private _languageSyncService: LanguageSyncService
    ) {
        super();

        this.subscriptions.push(_languageSyncService.getLanguage().subscribe({
            next: () => this.ngOnChanges(null)
        }));
    }

    ngOnChanges(_: SimpleChanges): void {
        this.processDetails();
    }

    private processDetails() {
        this.viewContainerRef.clear();
        const languageCode: string = this.globalService.getLanguageCode();
        if (this.details) {
            for (const detail of this.details) {
                if (detail.IsHeading) {
                    this.showHeading(detail.Title);
                } else {
                    switch (detail.DetailType) {
                        case 'CustAccount Selector':
                            this.showCustomerAccountSelector(detail);
                            break;
                        case 'Description':
                            this.showDescription(detail);
                            break;
                        case 'Form':
                            this.showForm(detail);
                            break;
                        case 'Summary':
                            break;
                    }
                }
            }
        }
    }

    private showForm(detail: PageDetail) {
        const ref: ComponentRef<FormDetailComponent> =
            this.viewContainerRef.createComponent(FormDetailComponent);

        ref.instance.formId = detail.FormId;
        ref.instance.pageId = detail.Id;
        ref.instance.returnUrl = this.returnUrl;
        ref.instance.fields = Form.convertToFormFields(detail.FormFieldDefinitions);
        ref.instance.dataQueryId = detail.DataQueryId;
        ref.instance.autoRunDataQuery = detail.AutoRunDataQuery;
        ref.instance.isLoginForm = detail.IsLoginForm;
        ref.instance.isAuthenticationForm = detail.IsAuthenticationForm;
        ref.changeDetectorRef.detectChanges();
    }

    private showCustomerAccountSelector(detail: PageDetail): void {
        const ref: ComponentRef<CustomerAccountSelectorComponent> =
            this.viewContainerRef.createComponent(CustomerAccountSelectorComponent);

        ref.instance.searchHelpText = detail.Title;
        ref.instance.searchButtonTitle = detail.Other;
        ref.instance.toolTip = detail.Description
        ref.changeDetectorRef.detectChanges();
    }

    showHeading(heading: string): void {
        const ref: ComponentRef<PageDescriptionHeadingComponent> =
            this.viewContainerRef.createComponent(PageDescriptionHeadingComponent);

        ref.instance.setHeading(heading);
        ref.changeDetectorRef.detectChanges();
    }

    showDescription(detail: PageDetail): void {
        const ref: ComponentRef<PageDescriptionComponent> =
            this.viewContainerRef.createComponent(PageDescriptionComponent);

        ref.instance.setDescription(detail.Description);
        ref.instance.setTitle(detail.Title);
        ref.instance.setOther(detail.Other);
        ref.changeDetectorRef.detectChanges();
    }
}
