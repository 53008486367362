import { Injectable } from '@angular/core';
import { Language } from '@models/language';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LanguageSyncService {
    private _languageSubject: Subject<Language> = new Subject<Language>();
    private _languagesSubject: Subject<Language[]> = new Subject<Language[]>();

    constructor() { }

    setLanguage(l: Language): void {
        this._languageSubject.next(l);
    }

    getLanguage(): Observable<Language> {
        return this._languageSubject.asObservable();
    }

    setLanguages(l: Language[]): void {
        this._languagesSubject.next(l);
    }

    getLanguages(): Observable<Language[]> {
        return this._languagesSubject.asObservable();
    }
}
