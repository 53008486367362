
<div>
    <div>
        <h2 mat-dialog-title class="title" style="width: 100%;">Customer Accounts - {{UserFullName}}</h2>
        <div style="clear: both"></div>
    </div>
</div>
<div style="padding: 24px;">
    <div style="height:180px;">
        <div style="clear: both; height: 10px; display: block;"></div>
        <p class="resultsHeading"><span>Current</span></p>
        <table mat-table [dataSource]="currentDataSource">
            <ng-container matColumnDef="accountNumber">
                <th mat-header-cell *matHeaderCellDef class="account-number"> Account </th>
                <td mat-cell *matCellDef="let account">{{account.Id}}</td>
            </ng-container>
            <ng-container matColumnDef="accountName">
                <th mat-header-cell *matHeaderCellDef class="account-name"> Name </th>
                <td mat-cell *matCellDef="let account">{{account.Name}}</td>
            </ng-container>
            <ng-container matColumnDef="accountLocation">
                <th mat-header-cell *matHeaderCellDef> Location </th>
                <td mat-cell *matCellDef="let account">{{account.Location}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true" class="list-header-row"></tr>
            <tr mat-row *matRowDef="let account; columns: displayColumns"
                class="list-data-row"
                [class.account-selected]="currentSelected === account"
                style="cursor: pointer; height: auto;"
                (click)="toggleCurrent(account)"
                (dblclick)="removeFromCurrent(account)">
            </tr>
        </table>
        <div style="margin-bottom: 10px;">
            <input type="button" (click)="removeFromCurrent(null)" [disabled]="!currentAccountSelected" value="Remove account" />
            <mat-paginator style="float: right; height: 25px;" [hidePageSize]="true" [pageSize]="3" showFirstLastButtons="true"></mat-paginator>
            <div style="clear: both"></div>
        </div>
    </div>
    <div style="padding-top: 20px;">
        <form>
            <div class="tooltip">
                <input tabindex="-1" type="text" [(ngModel)]="searchCriteria" style="width: 200px;" placeholder="Find account" name="criteria" />
                <span class="tooltiptext" style="margin-top: 5px;">Search by account, name, or location</span>
            </div>
            <input type="submit" (click)="searchAccounts()" value="Submit" />
        </form>
        <div style="clear: both; height: 10px; display: block;"></div>
        <div style="height: 200px;">
            <p class="resultsHeading"><span>Available</span></p>
            <table mat-table [dataSource]="availableDataSource">
                <ng-container matColumnDef="accountNumber">
                    <th mat-header-cell *matHeaderCellDef class="account-number"> Account </th>
                    <td mat-cell *matCellDef="let account">{{account.Id}}</td>
                </ng-container>
                <ng-container matColumnDef="accountName">
                    <th mat-header-cell *matHeaderCellDef class="account-name"> Name </th>
                    <td mat-cell *matCellDef="let account">{{account.Name}}</td>
                </ng-container>
                <ng-container matColumnDef="accountLocation">
                    <th mat-header-cell *matHeaderCellDef> Location </th>
                    <td mat-cell *matCellDef="let account">{{account.Location}}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true" class="list-header-row"></tr>
                <tr mat-row *matRowDef="let account; columns: displayColumns"
                    class="list-data-row"
                    [class.account-selected]="availableSelected === account"
                    style="cursor: pointer; height: auto;"
                    (click)="toggleAvailable(account)"
                    (dblclick)="addToCurrent(account)">
                </tr>
            </table>
        </div>
        <div style="margin-bottom: 10px;">
            <input type="button" (click)="addToCurrent(null)" [disabled]="availableAccountSelected === false" value="Add account" />
            <mat-paginator style="float: right; height: 25px;" [hidePageSize]="true" [pageSize]="5" showFirstLastButtons="true"></mat-paginator>
            <div style="clear: both"></div>
        </div>
    </div>
    <div>
        <div mat-dialog-actions style="display: flex; justify-content: space-between;">
            <input type="button" (click)="onSave()" value="Save" />
            <input type="button" (click)="onCancel()" value="Cancel" />
        </div>
    </div>
</div>
