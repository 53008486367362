import { Component, ElementRef, EventEmitter, Output, Renderer2 } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { saveAs } from 'file-saver';

import { environment } from '@environment';
import { LinkBaseComponent } from '../link-base/link-base.component';
import { SharedService } from '@services/shared.service';
import { UserService } from '@services/user.service';
import { GlobalService } from '@services/global.service';
import { FormService } from '@services/form.service';
import { PageService } from '@services/page.service';
import { ModalDataQueryConfigs } from '@models/modal-data-query-configs';
import { TreeNode } from '@models/tree-node';
import { IValidatedUser } from '@models/validated-user';
import { SharedHeader } from '@models/shared-header';
import { DataQueryOutputColumn, DataQueryOutputLinkTarget, DataQueryOutputRow, DataQueryResult } from '@models/data-query-result';
import { PageDetail } from '@models/page-detail';
import { PageSummary } from '@models/page-summary';
import { DataQueryDetail } from '@models/data-query-detail';
import { ColumnSortedEvent } from '../sortable-column/sort.service';
import { ImageLinkComponent } from '../image-link/image-link.component';

@Component({
    selector: 'cp-data-query-results-grid',
    templateUrl: './data-query-results-grid.component.html',
    styleUrls: ['./data-query-results-grid.component.less']
})
export class DataQueryResultsGridComponent {
    @Output() refreshPage: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() showModalResults: EventEmitter<ModalDataQueryConfigs> = new EventEmitter<ModalDataQueryConfigs>();

    showBusy: boolean;

    private currencyFormat: Intl.NumberFormat;
    private dateFormat: Intl.DateTimeFormat;
    private timeFormat: Intl.DateTimeFormat;
    private dateTimeFormat: Intl.DateTimeFormat;
    private _pendingDownloads: DataQueryOutputColumn[] = [];

    showHeader: boolean;
    showTotal: boolean;
    highlightFirst: boolean;
    highlightLast: boolean;
    bandedRows: boolean;
    bandedColumns: boolean;
    headers: any[] = [];
    rows: DataQueryOutputRow[] = [];
    footer: DataQueryOutputRow;
    id: string;
    datasetId: number;
    showNoResultsMessage: boolean;
    noResultsText: string;

    imageLocation: string = environment.ImageLocation;
    localImageLocation: string = environment.LocalImageLocation;

    languageCode: string;

    treeControl: NestedTreeControl<TreeNode> = new NestedTreeControl<TreeNode>((node) => node.Children);
    treeDataSource: MatTreeNestedDataSource<TreeNode> = new MatTreeNestedDataSource<TreeNode>();

    displayTree: boolean = false;
    get isTreeData(): boolean {
        return this.treeDataSource !== null;
    }

    fieldWidth(idx: number, node: TreeNode): string {
        if (this.headers[idx].width > 0) {
            return this.headers[idx].width + '%';
            // return this.headers[idx].width + 'px';
        }

        let otherFieldsWidth: number = 0;
        this.headers.forEach((h) => {
            otherFieldsWidth += h.width;
        });

        let level: number = 0;
        if (node) {
            level = node.Level;
        }

        const availableWidth: number = 540 - 25 - 10 * level;

        return otherFieldsWidth < availableWidth ? availableWidth - otherFieldsWidth + '%' : '0';
        // return otherFieldsWidth < availableWidth ? availableWidth - otherFieldsWidth + 'px' : '0';
    }

    fieldAlignment(idx: number): string {
        if (this.headers[idx].alignment && this.headers[idx].alignment !== '') {
            return this.headers[idx].alignment;
        }

        return 'auto';
    }

    treeNodeHasChild = (_: number, node: TreeNode) => !!node.Children && node.Children.length > 0;

    constructor(
        private _globalService: GlobalService,
        private _formService: FormService,
        private _elementRef: ElementRef,
        private _renderer: Renderer2,
        private _router: Router,
        private _pageService: PageService,
        private _sharedService: SharedService,
        private _userService: UserService
    ) {
        this.currencyFormat = new Intl.NumberFormat(this._globalService.getLanguage(), {
            // style: "currency",
            // currency: ???,
            // currencyDisplay: "symbol"
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        });

        this.dateFormat = new Intl.DateTimeFormat(this._globalService.getLanguage(), {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
        });

        this.timeFormat = new Intl.DateTimeFormat(this._globalService.getLanguage(), {
            hour12: true,
            hour: '2-digit',
            minute: '2-digit',
        });

        this.dateTimeFormat = new Intl.DateTimeFormat(this._globalService.getLanguage(), {
            hour12: true,
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        });
    }

    async processResultSet(resultSet: DataQueryResult): Promise<void> {
        this.languageCode = this._globalService.getLanguageCode();
        this.showHeader = resultSet.HeaderRow;
        this.showTotal = resultSet.TotalRow;
        this.highlightFirst = resultSet.FirstColumn;
        this.highlightLast = resultSet.LastColumn;
        this.bandedRows = resultSet.BandedRows;
        this.bandedColumns = resultSet.BandedColumns;
        this.id = resultSet.Id;
        this.datasetId = resultSet.DataSetIndex;
        this.showNoResultsMessage = resultSet.ShowNoResultsMessage;
        this.noResultsText = resultSet.NoResultsText;

        if (resultSet.QueryResults.length === 0 && !resultSet.QueryResultsTree) {
            if (resultSet.ShowHeaderWhenNoResults) {
                this.setupHeaders(resultSet.Details);
            } else {
                this.showHeader = false;
            }
        } else {
            this.setupHeaders(resultSet.Details);
        }

        if (resultSet.QueryResultsTree) {
            this.treeDataSource.data = [resultSet.QueryResultsTree];

            let count: number = 0;
            if (resultSet.QueryResults.length > 0) {
                count = resultSet.QueryResults[0].FieldValues.length;
            }
            this.setupTreeNodes(resultSet.QueryResultsTree, resultSet, count);

            this.displayTree = true;
        } else {
            this.treeDataSource = null;
        }

        let columnCount: number = 0;
        if (resultSet.QueryResults.length > 0) {
            columnCount = resultSet.QueryResults[0].FieldValues.length;
        }

        for (const data of resultSet.QueryResults) {
            const currentRow: DataQueryOutputRow = new DataQueryOutputRow();
            currentRow.columns = [];
            for (let i = 0; i < columnCount; ++i) {
                const currentColumn: DataQueryOutputColumn = new DataQueryOutputColumn();
                currentColumn.imageWidth = -1;
                currentColumn.imageHeight = -1;
                switch (resultSet.Details[i].DetailType) {
                    case 'IOString':
                        currentColumn.value = data.FieldValues[i];
                        currentColumn.alignment = resultSet.Details[i].Alignment;
                        currentColumn.name = resultSet.Details[i].ColumnName;
                        currentColumn.specialType = 'iostring';
                        currentColumn.width = resultSet.Details[i].Width;
                        currentRow.columns.push(currentColumn);
                        break;
                    case 'IOStringRequired':
                        currentColumn.value = data.FieldValues[i];
                        currentColumn.alignment = resultSet.Details[i].Alignment;
                        currentColumn.name = resultSet.Details[i].ColumnName;
                        currentColumn.specialType = 'iostringrequired';
                        currentColumn.width = resultSet.Details[i].Width;
                        currentRow.columns.push(currentColumn);
                        break;
                    case 'FieldMoney':
                        currentColumn.value = this.currencyFormat.format(data.FieldValues[i]);
                        currentColumn.alignment = resultSet.Details[i].Alignment;
                        currentColumn.width = resultSet.Details[i].Width;
                        currentRow.columns.push(currentColumn);
                        break;
                    case 'FieldString':
                        if (resultSet.Details[i].ReferenceSortOrder > 0) {
                            const target: DataQueryOutputLinkTarget = this.parseDestination(data.FieldValues, resultSet.Details, i);
                            currentColumn.isModal = target.isModal;
                            if (target.route.substring(0, 4) === 'http') {
                                currentColumn.destinationRoute = target.route;
                                currentColumn.linkTarget = 'anchorUrl';
                            } else if (target.route.substring(0, 11) === 'filestream/') {
                                currentColumn.destinationRoute = target.params;
                                currentColumn.destinationRouteParams = target.route.substring(target.route.indexOf('/') + 1);
                                currentColumn.specialType = target.streamType;
                                currentColumn.linkTarget = 'filestream/string';
                                if (await this.getFilestreamExists(currentColumn.destinationRoute, target.streamType) == false) {
                                    data.FieldValues[i] = null;
                                }
                            } else if (target.route.substring(0, 10) === 'dataquery/') {
                                currentColumn.destinationRoute = '/';
                                currentColumn.destinationRouteParams = target.params;
                                currentColumn.linkTarget = 'dataquery/string';
                            } else {
                                currentColumn.destinationRoute = `/${target.route.toLowerCase()}`;
                                currentColumn.destinationRouteParams = target.params;
                                currentColumn.linkTarget = 'anchor';
                            }
                        }
                        currentColumn.value = data.FieldValues[i];
                        currentColumn.alignment = resultSet.Details[i].Alignment;
                        currentColumn.width = resultSet.Details[i].Width;
                        currentRow.columns.push(currentColumn);
                        break;
                    case 'FieldDate':
                        const d: Date = new Date(data.FieldValues[i]);
                        if (data.FieldValues[i] !== null && d.getFullYear() > 1900) {
                            currentColumn.value = this.dateFormat.format(d);
                        } else {
                            currentColumn.value = '';
                        }
                        currentColumn.alignment = resultSet.Details[i].Alignment;
                        currentColumn.width = resultSet.Details[i].Width;
                        currentRow.columns.push(currentColumn);
                        break;
                    case 'FieldTime':
                        const t: Date = new Date(data.FieldValues[i]);
                        currentColumn.value = this.timeFormat.format(t);
                        currentColumn.width = resultSet.Details[i].Width;
                        currentRow.columns.push(currentColumn);
                        break;
                    case 'FieldDateTime':
                        const dt: Date = new Date(data.FieldValues[i]);
                        if (data.FieldValues[i] !== null && dt.getFullYear() > 1900) {
                            currentColumn.value = this.dateTimeFormat.format(dt);
                        } else {
                            currentColumn.value = '';
                        }
                        currentColumn.width = resultSet.Details[i].Width;
                        currentRow.columns.push(currentColumn);
                        break;
                    case 'FieldNumber':
                        currentColumn.value = data.FieldValues[i];
                        currentColumn.alignment = resultSet.Details[i].Alignment;
                        currentColumn.width = resultSet.Details[i].Width;
                        currentRow.columns.push(currentColumn);
                        break;
                    case 'TargetFilestream':
                    case 'TargetRelativeURL':
                    case 'TargetURL':
                    case 'TargetSQLAction':
                        break;
                    case 'FieldImage':
                        if (resultSet.Details[i].ReferenceSortOrder > 0) {
                            const target: DataQueryOutputLinkTarget = this.parseDestination(data.FieldValues, resultSet.Details, i);
                            currentColumn.isModal = target.isModal;
                            if (target.route.substring(0, 4) === 'http') {
                                currentColumn.destinationRoute = target.route;
                                currentColumn.linkTarget = 'imageUrl';
                            } else if (target.route.substring(0, 11) === 'filestream/') {
                                currentColumn.destinationRoute = target.params;
                                currentColumn.destinationRouteParams = target.route.substring(target.route.indexOf('/') + 1);
                                currentColumn.specialType = target.streamType;
                                currentColumn.linkTarget = 'filestream/image';
                                if (await this.getFilestreamExists(currentColumn.destinationRoute, target.streamType) == false) {
                                    data.FieldValues[i] = '';
                                }
                            } else if (target.route.substring(0, 10) === 'dataquery/') {
                                currentColumn.destinationRoute = '/';
                                currentColumn.destinationRouteParams = target.params;
                                currentColumn.linkTarget = 'dataquery/image';
                            } else {
                                currentColumn.destinationRoute = `/${target.route.toLowerCase()}`;
                                currentColumn.destinationRouteParams = target.params;
                                currentColumn.linkTarget = 'image';
                            }
                        }
                        currentColumn.value = data.FieldValues[i];
                        currentColumn.alignment = resultSet.Details[i].Alignment;
                        currentColumn.imageWidth = resultSet.Details[i].Width;
                        currentRow.columns.push(currentColumn);
                        break;
                    case 'FieldFilestream':
                        currentColumn.specialType = resultSet.Details[i].FilestreamType.toLowerCase();
                        currentColumn.value = data.FieldValues[i];
                        currentColumn.alignment = resultSet.Details[i].Alignment;
                        currentColumn.imageWidth = resultSet.Details[i].ImageWidth;
                        currentColumn.imageHeight = resultSet.Details[i].ImageHeight;
                        currentRow.columns.push(currentColumn);
                        break;
                    default:
                        currentColumn.value = data.FieldValues[i];
                        currentColumn.alignment = resultSet.Details[i].Alignment;
                        currentColumn.width = resultSet.Details[i].Width;
                        currentRow.columns.push(currentColumn);
                }
            }

            this.rows.push(currentRow);
        }

        if (this.showTotal && this.rows.length > 0) {
            this.footer = this.rows.pop();
        }
    }

    executeLink(column: DataQueryOutputColumn, fieldIndex: number | null = null, control: LinkBaseComponent = null): void {
        if (column.isModal) {
            if (control) {
                control.busy = true;
            }
            switch (column.linkTarget) {
                case 'anchor':
                case 'image':
                    this.showModal(column.destinationRoute, column.destinationRouteParams, control);
                    break;
                case 'anchorUrl':
                case 'imageUrl':
                    this._router.navigateByUrl(column.destinationRoute);
                    break;
                case 'filestream/string':
                case 'filestream/image':
                    this.getFilestream(column.destinationRoute, column.destinationRouteParams, column.specialType, control);
                    break;
                case 'dataquery/image':
                case 'dataquery/string':
                    this.showModal(column.destinationRoute, column.destinationRouteParams, control);
                    break;
            }
        } else {
            switch (column.linkTarget) {
                case 'anchor':
                case 'image':
                    this._router.navigate([column.destinationRoute], {
                        queryParams: column.destinationRouteParams,
                    });
                    break;
                case 'anchorUrl':
                case 'imageUrl':
                    this._router.navigateByUrl(column.destinationRoute);
                    break;
                case 'filestream/string':
                case 'filestream/image':
                    this.getFilestream(column.destinationRoute, column.destinationRouteParams, column.specialType, control);
                    this._pendingDownloads.push(column);
                    break;
                case 'dataquery/image':
                case 'dataquery/string':
                    this.executeDataQuery(column.destinationRouteParams, fieldIndex);
                    break;
            }
        }
    }

    executeDataQuery(dq: string, idx: number): void {
        if (!dq || dq === '') {
            return;
        }

        let invalidInput: boolean = false;
        const row: DataQueryOutputRow = this.rows[0];
        row.columns.forEach((col: DataQueryOutputColumn) => {
            if (col.name && col.name !== '') {
                const field = this._elementRef.nativeElement.querySelector(`#${col.name}_${idx}`);
                if (field) {
                    if (field.required && field.value === '') {
                        invalidInput = true;
                        this._renderer.setStyle(field, 'border-color', 'red');
                        this._renderer.setStyle(field, 'border-style', 'solid');
                        return;
                    }
                    this._renderer.setStyle(field, 'border-color', '');
                    this._renderer.setStyle(field, 'border-style', '');

                    dq = dq.replace('!' + col.name + '!', field.value);
                }
            }
        });

        if (!invalidInput) {
            this._formService.executeDataQuery(dq, {}).subscribe({
                next: () => this.refreshPage.emit(true)
            });
        }
    }

    private getPageId(url: string): string {
        const startIdx: number = url.indexOf('/page/');
        if (startIdx > 0) {
            const endIdx: number = url.indexOf('?');
            if (endIdx > 0) {
                return url.substring(startIdx + 6, endIdx - startIdx);
            } else {
                return url.substring(startIdx + 6);
            }
        }
        return '';
    }

    private constructFormData(pageId: string, params: object): any {
        const formData: { [id: string]: string } = {};
        formData['objectId'] = pageId;
        formData['objectType'] = 'page';
        formData['LanguageId'] = this._globalService.getLanguage();

        Object.keys(params).forEach((key: string) => {
            formData['QueryString-' + key.toLowerCase()] = params[key];
        });

        const user: IValidatedUser = this._userService.getCurrentUser();
        if (user) {
            formData['loginContext'] = user.UserId;
        }

        return formData;
    }

    async showModal(destination: string, params: object, callingControl: LinkBaseComponent = null): Promise<void> {
        const pageId: string = this.getPageId(destination);
        const formData = this.constructFormData(pageId, params);
        const pageHeader: SharedHeader =
            await lastValueFrom(this._sharedService.getHeader(pageId)).then((header: SharedHeader) => header);
        const pageDetails: PageDetail[] =
            await lastValueFrom(this._pageService.getPage(pageId)).then((summary: PageSummary) => summary.Details);
        const dataQueryId: string = pageDetails[0].DataQueryId;
        const formId: string = pageDetails[0].FormId;

        const results =
            await lastValueFrom(this._formService.submitDataQuery(formId, dataQueryId, formData)).then((r: DataQueryResult[]) => r);
        if (callingControl) {
            callingControl.busy = false;
        }

        this.showModalResults.emit({
            title: pageHeader.Title,
            dataQueryResults: results,
        });
    }

    getFilestream(fileName: string, streamType: string, fileType: string, control: LinkBaseComponent): void {
        fileType = "customerportal";

        const pdfFile: boolean = fileName.toLowerCase().endsWith('.pdf');
        this._formService.getFilestream(fileName, fileType, streamType, pdfFile).subscribe({
            next: (response: any) => {
                if (!response) {
                    alert('File does not exist.');
                    return;
                }

                if (pdfFile) {
                    const blobContents: Blob = this.b64toBlob(response, 'application/octet-stream', 512);
                    saveAs(blobContents, fileName);
                } else {
                    saveAs(response, fileName);
                }
            },
            error: (error: any) => {
                alert('Error fetching file.');
            },
            complete: () => {
                if (control) {
                    control.busy = false;
                }
            }
        });
    }

    async getFilestreamExists(fileName: string, streamType: string): Promise<boolean> {
        // var fileType = "customerportal";

        // let availability: boolean = await this._formService.getFilestreamExists(fileName, fileType, streamType);
        // return availability;
        return true;
    }

    b64toBlob(b64Data: string, contentType: string, sliceSize: number): Blob {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        const byteCharacters: string = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice: string = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray: Uint8Array = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        const blob: Blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    private setupHeaders(details: DataQueryDetail[]): void {
        for (const detail of details) {
            if (!detail.DetailType.startsWith('Target')) {
                if (detail.DetailType.toLowerCase() !== 'treerecid' && detail.DetailType.toLowerCase() !== 'treeparentrecid') {
                    this.headers.push({
                        text: detail.Title,
                        alignment: detail.Alignment,
                        width: detail.Width,
                        sortable: detail.Sortable,
                        datatype: detail.DataType,
                        requiredField: detail.DetailType.toLowerCase() === 'iostringrequired',
                    });
                }
            }
        }
    }

    private parseDestination(fieldValues: any[], columnDefinitions: DataQueryDetail[], sourceIndex: number): DataQueryOutputLinkTarget {
        const refSortOrder: number = columnDefinitions[sourceIndex].ReferenceSortOrder;
        if (refSortOrder === 0) {
            return null;
        } else {
            for (let i = 0; i < columnDefinitions.length; ++i) {
                if (columnDefinitions[i].SortOrder === refSortOrder) {
                    if (columnDefinitions[i].DetailType === 'TargetRelativeURL') {
                        const target: DataQueryOutputLinkTarget = this.parseUrl(fieldValues[i]);
                        // target.params['dt'] = 1;
                        target.isModal = columnDefinitions[i].IsModal;
                        return target;
                    } else if (columnDefinitions[i].DetailType === 'TargetURL') {
                        const target: DataQueryOutputLinkTarget = this.parseUrl(fieldValues[i]);
                        target.isModal = columnDefinitions[i].IsModal;
                        return target;
                    } else if (columnDefinitions[i].DetailType === 'TargetSQLAction') {
                        return {
                            route: 'dataquery/',
                            params: fieldValues[i],
                            streamType: '',
                            isModal: columnDefinitions[i].IsModal,
                        };
                    } else {
                        return {
                            route: 'filestream/' + columnDefinitions[i].FilestreamType,
                            params: fieldValues[i],
                            streamType: columnDefinitions[i].FilestreamTypeId,
                            isModal: columnDefinitions[i].IsModal,
                        };
                    }
                }
            }
            return null;
        }
    }

    private parseUrl(url: string): DataQueryOutputLinkTarget {
        let idx: number;

        const linkTarget: DataQueryOutputLinkTarget = new DataQueryOutputLinkTarget();

        if (!url || url === '') {
            linkTarget.route = '';
            return linkTarget;
        }

        if (url.startsWith('http')) {
            linkTarget.route = url;
            linkTarget.params = {};
            return linkTarget;
        }

        idx = url.indexOf('?');
        if (idx > 0) {
            linkTarget.route = url.substring(0, idx);
            url = url.substring(idx + 1);
        } else {
            linkTarget.route = url;
            linkTarget.params = {};
            return linkTarget;
        }

        linkTarget.params = {};
        const params: string[] = url.split('&');
        params.forEach((param: string) => {
            idx = param.indexOf('=');
            linkTarget.params[param.substring(0, idx)] = param.substring(idx + 1);
        });

        return linkTarget;
    }

    onSorted(id: string, datasetId: number, $event: ColumnSortedEvent): void {
        if (this.id === id && this.datasetId === $event.tableId && this.id === $event.queryId) {
            this.rows.sort((a: DataQueryOutputRow, b: DataQueryOutputRow) => {
                if ($event.sortDirection === 'desc') {
                    if (a.columns[$event.sortColumn].value < b.columns[$event.sortColumn].value) {
                        return -1;
                    }
                    if (a.columns[$event.sortColumn].value > b.columns[$event.sortColumn].value) {
                        return 1;
                    }
                    return 0;
                } else {
                    if (a.columns[$event.sortColumn].value > b.columns[$event.sortColumn].value) {
                        return -1;
                    }
                    if (a.columns[$event.sortColumn].value < b.columns[$event.sortColumn].value) {
                        return 1;
                    }
                    return 0;
                }
            });
        }
    }

    private setupTreeNodes(tree: TreeNode, resultSet: DataQueryResult, columnCount: number): void {
        this.processTreeNodeData(tree, resultSet, columnCount);
        tree.Children.forEach((node: TreeNode) => {
            this.setupTreeNodes(node, resultSet, columnCount);
        });
    }

    private processTreeNodeData(node: TreeNode, resultSet: DataQueryResult, columnCount: number): void {
        node['columns'] = [];
        for (let i = 0; i < columnCount; ++i) {
            const currentColumn: DataQueryOutputColumn = new DataQueryOutputColumn();
            currentColumn.imageWidth = -1;
            currentColumn.imageHeight = -1;
            switch (resultSet.Details[i].DetailType) {
                case 'IOString':
                    currentColumn.value = node.FieldValues[i];
                    currentColumn.alignment = resultSet.Details[i].Alignment;
                    currentColumn.name = resultSet.Details[i].ColumnName;
                    currentColumn.specialType = 'iostring';
                    currentColumn.width = resultSet.Details[i].Width;
                    node['columns'].push(currentColumn);
                    break;
                case 'IOStringRequired':
                    currentColumn.value = node.FieldValues[i];
                    currentColumn.alignment = resultSet.Details[i].Alignment;
                    currentColumn.name = resultSet.Details[i].ColumnName;
                    currentColumn.specialType = 'iostringrequired';
                    currentColumn.width = resultSet.Details[i].Width;
                    node['columns'].push(currentColumn);
                    break;
                case 'FieldMoney':
                    currentColumn.value = this.currencyFormat.format(node.FieldValues[i]);
                    currentColumn.alignment = resultSet.Details[i].Alignment;
                    currentColumn.width = resultSet.Details[i].Width;
                    node['columns'].push(currentColumn);
                    break;
                case 'FieldString':
                    if (resultSet.Details[i].ReferenceSortOrder > 0) {
                        const target: DataQueryOutputLinkTarget = this.parseDestination(node.FieldValues, resultSet.Details, i);
                        currentColumn.isModal = target.isModal;
                        if (target.route.substring(0, 4) === 'http') {
                            currentColumn.destinationRoute = target.route;
                            currentColumn.linkTarget = 'anchorUrl';
                        } else if (target.route.substring(0, 11) === 'filestream/') {
                            currentColumn.destinationRoute = target.params;
                            currentColumn.destinationRouteParams = target.route.substring(target.route.indexOf('/') + 1);
                            currentColumn.specialType = target.streamType;
                            currentColumn.linkTarget = 'filestream/string';
                        } else if (target.route.substring(0, 10) === 'dataquery/') {
                            currentColumn.destinationRoute = '/';
                            currentColumn.destinationRouteParams = target.params;
                            currentColumn.linkTarget = 'dataquery/string';
                        } else {
                            currentColumn.destinationRoute = `/${target.route.toLowerCase()}`;
                            currentColumn.destinationRouteParams = target.params;
                            currentColumn.linkTarget = 'anchor';
                        }
                    }
                    currentColumn.value = node.FieldValues[i];
                    currentColumn.alignment = resultSet.Details[i].Alignment;
                    currentColumn.width = resultSet.Details[i].Width;
                    node['columns'].push(currentColumn);
                    break;
                case 'FieldDate':
                    const d: Date = new Date(node.FieldValues[i]);
                    if (d.getFullYear() > 1900) {
                        currentColumn.value = this.dateFormat.format(d);
                    } else {
                        currentColumn.value = '';
                    }
                    currentColumn.alignment = resultSet.Details[i].Alignment;
                    currentColumn.width = resultSet.Details[i].Width;
                    node['columns'].push(currentColumn);
                    break;
                case 'FieldTime':
                    const t: Date = new Date(node.FieldValues[i]);
                    currentColumn.value = this.timeFormat.format(t);
                    currentColumn.width = resultSet.Details[i].Width;
                    node['columns'].push(currentColumn);
                    break;
                case 'FieldDateTime':
                    const dt: Date = new Date(node.FieldValues[i]);
                    if (dt.getFullYear() > 1900) {
                        currentColumn.value = this.dateTimeFormat.format(dt);
                    } else {
                        currentColumn.value = '';
                    }
                    currentColumn.width = resultSet.Details[i].Width;
                    node['columns'].push(currentColumn);
                    break;
                case 'FieldNumber':
                    currentColumn.value = node.FieldValues[i];
                    currentColumn.alignment = resultSet.Details[i].Alignment;
                    currentColumn.width = resultSet.Details[i].Width;
                    node['columns'].push(currentColumn);
                    break;
                case 'TargetFilestream':
                case 'TargetRelativeURL':
                case 'TargetURL':
                case 'TargetSQLAction':
                case 'TreeRecId':
                case 'TreeParentRecId':
                    break;
                case 'FieldImage':
                    if (resultSet.Details[i].ReferenceSortOrder > 0) {
                        const target: DataQueryOutputLinkTarget = this.parseDestination(node.FieldValues, resultSet.Details, i);
                        currentColumn.isModal = target.isModal;
                        if (target.route.substring(0, 4) === 'http') {
                            currentColumn.destinationRoute = target.route;
                            currentColumn.linkTarget = 'imageUrl';
                        } else if (target.route.substring(0, 11) === 'filestream/') {
                            currentColumn.destinationRoute = target.params;
                            currentColumn.destinationRouteParams = target.route.substring(target.route.indexOf('/') + 1);
                            currentColumn.specialType = target.streamType;
                            currentColumn.linkTarget = 'filestream/image';
                        } else if (target.route.substring(0, 10) === 'dataquery/') {
                            currentColumn.destinationRoute = '/';
                            currentColumn.destinationRouteParams = target.params;
                            currentColumn.linkTarget = 'dataquery/image';
                        } else {
                            currentColumn.destinationRoute = `/${target.route.toLowerCase()}`;
                            currentColumn.destinationRouteParams = target.params;
                            currentColumn.linkTarget = 'image';
                        }
                    }
                    currentColumn.value = node.FieldValues[i];
                    currentColumn.alignment = resultSet.Details[i].Alignment;
                    currentColumn.imageWidth = resultSet.Details[i].Width;
                    node['columns'].push(currentColumn);
                    break;
                case 'FieldFilestream':
                    currentColumn.specialType = resultSet.Details[i].FilestreamType.toLowerCase();
                    currentColumn.value = node.FieldValues[i];
                    currentColumn.alignment = resultSet.Details[i].Alignment;
                    currentColumn.imageWidth = resultSet.Details[i].ImageWidth;
                    currentColumn.imageHeight = resultSet.Details[i].ImageHeight;
                    node['columns'].push(currentColumn);
                    break;
                default:
                    currentColumn.value = node.FieldValues[i];
                    currentColumn.alignment = resultSet.Details[i].Alignment;
                    currentColumn.width = resultSet.Details[i].Width;
                    node['columns'].push(currentColumn);
            }
        }
    }
}
