import { Component } from '@angular/core';

import { GlobalService } from '@services/global.service';
import { GeneralInfo } from '@models/general-info';
import { LanguageSyncService } from '@services/language-sync.service';
import { Language } from '@models/language';

@Component({
    selector: 'cp-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.less']
})
export class FooterComponent {
    info: GeneralInfo = null;

    get currentYear(): number {
        return new Date().getFullYear();
    }

    constructor(private _globalService: GlobalService, private _languageSyncService: LanguageSyncService) {
        this.initialize();

        this._languageSyncService.getLanguage().subscribe(
            (l: Language) => {
                this.initialize();
            }
        );
    }


    initialize(): void {
        this._globalService.getGeneralInfo().subscribe(
            (response: GeneralInfo) => {
                this.info = response;
            }
        )
    }

}
