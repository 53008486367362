import { Component, OnInit } from '@angular/core';
import { Breadcrumb } from '@models/breadcrumb';
import { PageBaseComponent } from 'src/app/base/page-base/page-base.component';

@Component({
  selector: 'cp-customer-selection',
  templateUrl: './customer-selection.component.html',
  styleUrls: ['./customer-selection.component.less']
})
export class CustomerSelectionComponent extends PageBaseComponent implements OnInit {
    breadcrumbs: Breadcrumb[] =
        [
            { label: 'Home', route: '/home' },
            { label: 'Account Selection', route: '' }
        ];

    protected override detailPageId: string = 'cpHome';

    // ngOnInit(): void {
    //     this.getDetails('cpHome');
    // }
}
