import { Component, OnInit } from '@angular/core';
import { PageBaseComponent } from '../base/page-base/page-base.component';

@Component({
  selector: 'cp-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.less']
})
export class AuthenticationComponent extends PageBaseComponent {
    override detailPageId: string = 'Authentication';
    // ngOnInit(): void {
    //     this.initialize();
    // }

    // protected override initialize(): void {
    //     this.getDetails('Authentication');
    // }
}
