import { Component, Input } from '@angular/core';
import { Breadcrumb } from '@models/breadcrumb';

@Component({
  selector: 'cp-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.less']
})
export class BreadcrumbComponent {
    @Input() breadcrumbs: Breadcrumb[] = [];
}
