<div class="formDetailContainer">
    <cp-form-base
        [formId]="formId"
        [pageId]="pageId"
        [fields]="fields"
        [returnUrl]="returnUrl"
        [dataQueryId]="dataQueryId"
        [autoRunDataQuery]="autoRunDataQuery"
        [isLoginForm]="isLoginForm"
        [isAuthenticationForm]="isAuthenticationForm"
    >
    </cp-form-base>
</div>

