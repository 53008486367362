import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { firstValueFrom, throwError } from 'rxjs';

import { environment } from '@environment';
import { FormService } from '@services/form.service';
// import { GlobalService } from '@services/global.service';
// import { UserService } from '@services/user.service';
import { FormFieldBase } from '@models/form-fields';
import { InternalDomain } from '@models/internal-domain';
import { GlobalService } from '@services/global.service';
// import { UsnrDomain } from '@models/usnr-domain';

@Component({
  selector: 'cp-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.less']
})
export class FormFieldComponent implements OnInit {
    @Input() field: FormFieldBase<any>;
    @Input() form: UntypedFormGroup;
    @Input() submitAttempt: boolean;
    private requireFile: boolean;
    // private captchaValid: boolean = false;
    private domainList: InternalDomain[] = [];

    @Output() clearForm: EventEmitter<void> = new EventEmitter<void>();

    @Output() uploadingFile: EventEmitter<boolean> = new EventEmitter<boolean>();
    private _uploadingFile: boolean = false;
    get isUploadingFile(): boolean {
        return this._uploadingFile;
    }

    dynamicChoices: any[];

    defaultExpenseCurrency: string = "USD";

    localImageLocation: string = environment.LocalImageLocation;

    constructor(
        private formService: FormService,
        private globalService: GlobalService,
        // private userService: UserService,
        // private imageService: NgxImageCompressService
    ) {
    }

    ngOnInit(): void {
        if (this.field.type === "file") {
            this.requireFile = this.field.required;
        }

        this.uploadingFile.emit(false);
        this._uploadingFile = false;
    }

    // ngAfterViewInit(): void {
    //     this.captchaValid = false;
    // }

    // ngAfterContentInit(): void {
    //     this.captchaValid = false;
    // }

    isInvalid(): boolean {
        const control: AbstractControl = this.form.get(this.field.key);

        let returnValue: boolean;

        if (this._uploadingFile) {
            returnValue = true;
        } else if (this.requireFile) {
            returnValue = this.submitAttempt && (this.form.controls["AttachedFile"].value === "");
        } else {
            returnValue = (!control.valid && this.submitAttempt);
        }

        return returnValue;
    }

    onCaptcha($event: any): void {
        this.form.controls[this.field.key].setValue($event);
    }

    onClearForm(): void {
        this.clearForm.emit();
    }

    private async getDomainList(): Promise<void> {
        await firstValueFrom(this.globalService.getDomainList()).then(
            (domainList: InternalDomain[]) => { this.domainList = domainList; }
        );
    }

    private async isInternalDomain(emailAddress: string): Promise<boolean> {
        if (!this.domainList.length) {
            await this.getDomainList();
        }

        const domainList: InternalDomain[] = this.domainList.filter((domain: InternalDomain) => {
            if (emailAddress.endsWith("@" + domain.Id)) {
                return domain;
            }
            return null;
         });

         return domainList.length > 0;
    }

    attachFileChange($event: any): void {
        const fileList: FileList = $event.target.files;
        if (fileList.length > 0) {
            this.uploadingFile.emit(true);
            this._uploadingFile = true;

            const formData: FormData = new FormData();
            formData.append("file[]", fileList[0]);

            this.formService.uploadFile("", formData).subscribe({
                next: (fileInfo: any) => {
                    this.form.controls["AttachedFile"].setValue(fileInfo.FileName);
                    this.form.controls["AttachedFileOriginalName"].setValue(fileInfo.OriginalFileName);
                    this.form.controls["AttachedFileType"].setValue(fileInfo.FileType);
                    this.uploadingFile.emit(false);
                    this._uploadingFile = false;
                },
                error: (error: any) => {
                    this.uploadingFile.emit(false);
                    this._uploadingFile = false;
                    this.handleError(error);
                }
            });
        }
    }

    // attachImageChange($event: any): void {
    //     const fileList: FileList = $event.target.files;
    //     let localUrl: string;
    //     if (fileList.length > 0) {
    //         this.uploadingFile.emit(true);
    //         this._uploadingFile = true;

    //         const file: File = fileList[0];
    //         const reader: FileReader = new FileReader();
    //         reader.onload = ((event: any) => {
    //             localUrl = event.target.result;
    //         });
    //         reader.readAsDataURL($event.target.files[0]);

    //         const imageSize: number = file.size;
    //         if (imageSize > (1024 * 1024)) {    // bigger than 1Mb
    //             this.compressAndUploadImage(localUrl, ((1024 * 1024) / imageSize) * 100, file);
    //         }
    //     }
    // }

    // private compressAndUploadImage(localFile: string, compressRatio: number, file: File): void {
    //     this.imageService.compressFile(localFile, -1, compressRatio).then(result => {
    //         const compressedFile: File = new File([result], file.name, { type: file.type });

    //         const formData: FormData = new FormData();
    //         formData.append("file[]", compressedFile);

    //         this.formService.uploadFile("", formData).subscribe(
    //             (fileInfo: any) => {
    //                 this.form.controls["AttachedFile"].setValue(fileInfo.FileName);
    //                 this.form.controls["AttachedFileOriginalName"].setValue(file.name);
    //                 this.form.controls["AttachedFileType"].setValue(fileInfo.FileType);
    //                 this.uploadingFile.emit(false);
    //                 this._uploadingFile = false;
    //             },
    //             (internalError: any) => { this.handleError(internalError); }
    //         );
    //     });
    // }

    private handleError(error: any) {
        console.error("An error occurred", error);
        throwError(error);
    }
}
