<div style="overflow: hidden;">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle  style="float: left; cursor: grab;">
        <mat-icon style="vertical-align: middle;">open_with</mat-icon>
    </div>
    <h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="margin-bottom: 0; cursor: grab; margin-left: 10px; float: left;">
        {{title}}
    </h2>
    <div style="float: right;">
        <button mat-button class="close-icon" [mat-dialog-close]="true">
            <mat-icon>close_icon</mat-icon>
        </button>
    </div>
</div>
<cp-data-query-results [data]="dataQueryResults" (refreshPage)="onRefreshPage()"></cp-data-query-results>
