import { UserRole } from './user-role';

export class User {
    Id: string; // email
    Role: UserRole;
    FirstName: string;
    LastName: string;
    CompanyName: string;
    Enabled: boolean;
}
