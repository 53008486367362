<div style="margin: 5px; position: relative; display: inline-block;">
    <div style="cursor: pointer;"
        [style.width.px]="width" [style.max-width.px]="maxWidth"
        [style.height.px]="height" [style.max-height.px]="maxHeight"
        [style.color]="color" [style.backgroundColor]="tileBackgroundColor"
        (mouseup)="buttonClicked($event)"
        (mouseover)="hover = true"
        (mouseleave)="hover = false"
    >
        <div style="margin-left: 5px; padding-top: 5px; font-size: 16px; line-height: 16px; text-align: left;">
            <span><ng-content></ng-content></span>
        </div>
        <div style="position: absolute; bottom: 0; left: 0px; height: 70px; line-height: 70px;" [style.width.px]="width" [style.max-width.px]="maxWidth">
            <mat-icon style="font-size: 70px; opacity: 30%; overflow: initial;" [fontIcon]="icon"></mat-icon>
        </div>
    </div>
</div>
