export class FormFieldBase<T> {
    type: string;
    value?: T;
    key: string;
    label: string;
    placeholder: string;
    order: number;
    controlType: string;
    extra: string;
    width: number;
    height: number;
    description: string;

    options: { key: string, value: string }[] = [];
    pattern: string;
    required: boolean;
    validationText: string;

    formId: string;

    constructor(
        options: {
            formId?: string,
            value?: T,
            key?: string,
            label?: string,
            placeholder?: string,
            required?: boolean,
            order?: number,
            controlType?: string,
            extra?: string,
            width?: number,
            height?: number,
            pattern?: string,
            description?: string,
            other?: string,
            validationText?: string;
        } = { }) {

        this.formId = options.formId || "";
        this.value = options.value;
        this.key = options.key || "";
        this.label = options.label || "";
        this.order = options.order === undefined ? 1 : options.order;
        this.controlType = options.controlType || "";
        this.extra = options.extra || "";
        this.width = options.width;
        this.height = options.height;
        this.required = !!options.required;
        this.pattern = options.pattern || "";
        this.description = options.description || "";
        this.validationText = options.validationText || "";
        this.placeholder = options.placeholder || '';
    }

    resetToDefault(): void {}
}

export class FormFieldAttachFileButton extends FormFieldBase<string> {
    override controlType: string = "buttonAttachFile";
    override type: string = "file";

    constructor(options: {} = {}) {
        super(options);
    }
}

export class FormFieldAttachImageButton extends FormFieldBase<string> {
    override controlType: string = "buttonAttachImage";
    override type: string = "file";

    constructor(options: {} = {}) {
        super(options);
    }
}

export class FormFieldCaptcha extends FormFieldBase<string> {
    override controlType: string = "captcha";

    constructor(options: {} = {}) {
        super(options);
    }
}

export class FormFieldChoice extends FormFieldBase<string> {
    override controlType: string = "choice";
    override options: { key: string, value: string }[] = [];

    constructor(options: {} = {}) {
        super(options);
        this.options = options["options"] || [];
        this.setDefault();
    }

    override resetToDefault(): void {
        this.setDefault();
    }

    private setDefault(): void {
        if (this.options.length > 0) {
            this.value = this.options[0].value;
        }
    }
}

export class FormFieldClearButton extends FormFieldBase<string> {
    override controlType: string = "buttonClear";
    override type: string = "reset";

    constructor(options: {} = {}) {
        super(options);
    }
}

export class FormFieldDate extends FormFieldBase<string> {
    override controlType: string = "date";
    override type: string;

    constructor(options: {} = {}) {
        super(options);
        this.type = options["type"] || "";
    }
}

export class FormFieldDescription extends FormFieldBase<string> {
    override controlType: string = "description";
    override type: string;

    constructor(options: {} = {}) {
        super(options);
        this.type = options["type"] || "";
    }
}

export class FormFieldEmail extends FormFieldBase<string> {
    override controlType: string = "email";
    override type: string;

    constructor(options: {} = {}) {
        super(options);
        this.type = options["type"] || "";
    }
}

export class FormFieldFailureMessage extends FormFieldBase<string> {
    override controlType: string = "msgFailure";
    override type: string;
    targetPageId: string;

    constructor(options: {} = {}) {
        super(options);
        this.type = options["type"] || "";
    }
}

export class FormFieldHeading extends FormFieldBase<string> {
    override controlType: string = "heading";
    override type: string;

    constructor(options: {} = {}) {
        super(options);
        this.type = options["type"] || "";
    }
}

export class FormFieldHidden extends FormFieldBase<string> {
    override controlType: string = "hidden";
    override type: string = "hidden";

    constructor(options: {} = {}) {
        super(options);
    }
}

export class FormFieldMultiLineText extends FormFieldBase<string> {
    override controlType: string = "multiLineText";
    override type: string;

    constructor(options: {} = {}) {
        super(options);
        this.type = options["type"] || "";
    }
}

export class FormFieldNumber extends FormFieldBase<string> {
    override controlType: string = "number";

    constructor(options: {} = {}) {
        super(options);
        this.type = options["type"] || "";
    }
}

export class FormFieldPassword extends FormFieldBase<string> {
    override controlType: string = "password";
    override type: string = "password";

    constructor(options: {} = {}) {
        super(options);
    }
}

export class FormFieldResetPasswordButton extends FormFieldBase<string> {
    override controlType: string = "buttonResetPassword";
    override type: string = "submit";

    constructor(options: {} = {}) {
        super(options);
    }
}

export class FormFieldSubmitAndClearButtons extends FormFieldBase<string> {
    override controlType: string = "buttonSubmitAndClear";
    targetPageId: string;

    constructor(options: {} = {}) {
        super(options);
        this.targetPageId = options["targetPageId"] || "";
    }
}

export class FormFieldSubmitButton extends FormFieldBase<string> {
    override controlType: string = "buttonSubmit";
    override type: string = "submit";
    targetPageId: string;

    constructor(options: {} = {}) {
        super(options);
        this.targetPageId = options["targetPageId"] || "";
    }
}

export class FormFieldCheckIdentityButton extends FormFieldBase<string> {
    override controlType: string = "buttonCheckIdentity";
    override type: string = "submit";
    targetPageId: string;
    override key: string = "checkIdentity";

    constructor(options: {} = {}) {
        super(options);
        this.targetPageId = options["targetPageId"] || "";
    }
}

export class FormFieldVerifyIdentityButton extends FormFieldBase<string> {
    override controlType: string = "buttonCheckIdentity";
    override type: string = "submit";
    targetPageId: string;
    override key: string = "verifyIdentity";

    constructor(options: {} = {}) {
        super(options);
        this.targetPageId = options["targetPageId"] || "";
    }
}

export class FormFieldIdentityValidation extends FormFieldBase<string> {
    override controlType: string = "singleLineText";
    override type: string;

    validationErrorMessage: string = this.key + " is required.";

    constructor(options: {} = {}) {
        super(options);
        this.type = options["type"] || "";
    }
}

export class FormFieldSingleLineText extends FormFieldBase<string> {
    override controlType: string = "singleLineText";
    override type: string;

    validationErrorMessage: string = this.key + " is required.";

    constructor(options: {} = {}) {
        super(options);
        this.type = options["type"] || "";
    }
}

export class FormFieldSpecial extends FormFieldBase<string> {
    override controlType: string = "special";

    constructor(options: {} = {}) {
        super(options);
    }
}

export class FormFieldSuccessMessage extends FormFieldBase<string> {
    override controlType: string = "msgSuccess";
    override type: string;

    constructor(options: {} = {}) {
        super(options);
        this.type = options["type"] || "";
    }
}

export class FormFieldValidationLink extends FormFieldBase<string> {
    override controlType: string = "msgValidationLink";
    override type: string;

    constructor(options: {} = {}) {
        super(options);
        this.type = options["type"] || "";
    }
}

export class FormFieldEmailSubject extends FormFieldBase<string> {
    override controlType: string = "msgEmailSubject";
    override type: string;

    constructor(options: {} = {}) {
        super(options);
        this.type = options["type"] || "";
    }
}

export class FormFieldEmailConfirm extends FormFieldBase<string> {
    override controlType: string = "msgEmailConfirm";
    override type: string;

    constructor(options: {} = {}) {
        super(options);
        this.type = options["type"] || "";
    }
}

export class FormFieldYesNo extends FormFieldBase<string> {
    override controlType: string = "yesno";

    constructor(options: {} = {}) {
        super(options);
    }
}
