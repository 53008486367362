import { Component, EventEmitter, Input, Output } from '@angular/core';

import { environment } from '@environment';

@Component({
  selector: 'dq-link-base',
  templateUrl: './link-base.component.html',
  styleUrls: ['./link-base.component.less']
})
export abstract class LinkBaseComponent {
    @Input() allowLink: boolean = true;
    @Output() linkSelected: EventEmitter<LinkBaseComponent> = new EventEmitter<LinkBaseComponent>();

    private _busy: boolean;
    get busy(): boolean {
        return this._busy;
    }
    set busy(b: boolean) {
        this._busy = b;
    }

    get localImageLocation(): string {
        return environment.LocalImageLocation;
    }

    constructor() { }

    onClick(): void {
        if (!this._busy) {
            this._busy = true;
            this.linkSelected.emit(this);
        }
    }
}
