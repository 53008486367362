import { Component, Input } from '@angular/core';

import { LinkBaseComponent } from '../link-base/link-base.component';
import { environment } from '@environment';

@Component({
    selector: 'dq-anchor',
    templateUrl: './anchor.component.html',
    styleUrls: ['./anchor.component.less']
})
export class AnchorComponent extends LinkBaseComponent {
    @Input() value: string;
    @Input() width: number;

    override get localImageLocation(): string {
        return environment.LocalImageLocation;
    }

    constructor() { super(); }
}
