import { Component } from '@angular/core';
import { GeneralInfo } from '@models/general-info';
import { GlobalService } from '@services/global.service';

@Component({
  selector: 'cp-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.less']
})
export class BannerComponent {
    info: GeneralInfo = null;

    constructor(private _globalService: GlobalService) {
        this._globalService.getGeneralInfo().subscribe(
            (response: GeneralInfo) => {
                this.info = response;
            }
        )
    }
}
