import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { UserMaintenanceComponent } from './user-maintenance/user-maintenance.component';
import { canViewUserMaintenance } from '@services/user.service';
import { CustomerSelectionComponent } from './pages/customer-selection/customer-selection.component';
import { hasAccountSelected, isLoggedIn } from '@services/can-activate.guard';
import { BrowserUtils } from '@azure/msal-browser';

const routes: Routes = [
    { path: 'accounts', component: CustomerSelectionComponent, canActivate: [ isLoggedIn ] },
    { path: 'login', component: LoginComponent },
    { path: 'Authentication', component: AuthenticationComponent },
    { path: 'home', component: HomeComponent, canActivate: [ isLoggedIn ] },
    { path: 'users', component: UserMaintenanceComponent, canActivate: [() => canViewUserMaintenance() ] },
    { path: "dtsi", component: LoginComponent },
    { path: '**', redirectTo: "/home" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Don't perform initial navigation in iframes or popups
    initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
            ? "enabledNonBlocking"
            : "disabled", // Set to enabledBlocking to use Angular Universal
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
